import { API_URLS } from "../configs/api.urls";
import { axiosPrivate } from "../configs/axios";

export const getBankAccountsByUserId = async () => {
  return await axiosPrivate({
    url: API_URLS.GET_BANK_ACCOUNTS_BY_USERID_URL,
    method: "GET",
  });
};

export const CreateBankAccount = async (data) => {
  return await axiosPrivate({
    url: API_URLS.CREATE_STRIPE_BANK_ACCOUNT_URL,
    method: "POST",
    data: data,
  });
};

export const UpdateBankAccount = async (id, data) => {
  return await axiosPrivate({
    url: API_URLS.UPDATE_STRIPE_BANK_ACCOUNT_URL + id,
    method: "PUT",
    data: data,
  });
};
