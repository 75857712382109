import { API_URLS } from "../configs/api.urls";
import { axiosPrivate } from "../configs/axios";

export const getstoragebookingByUserId = async (queryParams) => {
  return await axiosPrivate({
    url: API_URLS.GET_STORAGE_BOOKING_BY_USERID_URL,
    method: "GET",
    params: queryParams,
  });
};

export const getBookingByStorageId = async (storageId) => {
  return await axiosPrivate({
    url: API_URLS.GET_BOOKING_BY_STORAGE_ID_URL + storageId,
    method: "GET",
  });
};

export const getBookingByUserId = async (queryParams) => {
  return await axiosPrivate({
    url: API_URLS.GET_BOOKING_BY_USER_ID_URL,
    method: "GET",
    params: queryParams,
  });
};

export const getAllBooking = async (queryParams) => {
  return await axiosPrivate({
    url: API_URLS.GET_ALL_URL,
    method: "GET",
    params: queryParams,
  });
};

export const updateBookingStatus = async (id, data) => {
  return await axiosPrivate({
    url: API_URLS.UPDATE_BOOKING_STATUS_URL + id,
    method: "PUT",
    data: data,
  });
};
