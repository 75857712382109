/*
 * Copyright © 2023-2024 www.4bex.com
 *
 * Licensed under the www.4bex.com License, Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.4bex.com/license
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React from 'react';
import {Button, Form, Input, Select, Space} from 'antd';
import "./Search.css";

const SearchComponent = ({filters, onSearch}) => {
  const [form] = Form.useForm();

  const handleSearch = () => {
    form.validateFields().then(values => {
      onSearch(values);
    });
  };

  return (
    <div className="search-container">
      <Form
        form={form}
        className="search-form"
        labelCol={{
          span: 6,
        }}
        wrapperCol={{
          span: 18,
        }}
      >
        {filters.map(filter => (
          <Form.Item
            key={filter.key}
            layout="horizontal"
            className="search-conatiner-p1"
            labelAlign="left"
            name={filter.key}
            label={filter.label}
            rules={filter.rules || []} // Optionally add validation rules
          >
            {filter.type === 'input' ?
              <Input placeholder={`Enter ${filter.label}`}/> :
              <Select
                mode="multiple"
                style={{
                  width: '100%',
                }}
                placeholder={`Select ${filter.label}`}
                // onChange={handleChange}
                options={filter.optionArray}
                optionRender={(option) => (
                  <Space>
                    {option.label}
                  </Space>
                )}
              />
            }
          </Form.Item>
        ))}

        <Form.Item
          className="search-form-btn"
        >
          <Button type="primary" onClick={handleSearch} style={{marginTop: '5px'}}>
            Search
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default SearchComponent;
