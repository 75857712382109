/*
 * Copyright © 2023-2024 www.4bex.com
 *
 * Licensed under the www.4bex.com License, Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.4bex.com/license
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React from "react";
import { Modal, Form, Input, Statistic, Row, Col } from "antd";
import "./TransactionModal.css"; // Optional: Import custom styles
import moment from "moment/moment";

const TransactionModal = ({
  transaction,
  visible,
  onClose,
}) => {
  const {
    // category,
    transaction_amount,
    storage_name,
    transaction_status,
    transaction_day,
  } = transaction;

  return (
    <Modal
      title={`Transaction Details`}
      open={visible}
      onCancel={onClose}
      footer={null}
      width={600}
    >
      <Form layout="vertical">
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item label="Storage Name">
              <Input value={storage_name} readOnly />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Transaction Date">
              <Input
                value={moment(transaction_day).format("YYYY/MM/DD")}
                readOnly
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item label="Amount">
              <Statistic value={transaction_amount} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Transaction Status">
              <Input value={transaction_status.toUpperCase()} readOnly />
            </Form.Item>
          </Col>
        </Row>
        {/* {category === "debit" &&
          status !== "processing" &&
          status !== "processed" && (
            <Row>
              <Col span={24}>
                <Button
                  type="primary"
                  onClick={() => onProcessPayment(transaction)}
                >
                  Process Payment
                </Button>
              </Col>
            </Row>
          )} */}
      </Form>
    </Modal>
  );
};
export default TransactionModal;
