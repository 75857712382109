import React from "react";
import { Button, message, Result, Spin, Typography } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { LoadingOutlined, MailOutlined } from "@ant-design/icons";
import { API_URLS } from "../../common/configs/api.urls";
import useAxios from "../../common/hooks/axios";

const { Paragraph } = Typography;

const EmailVerify = () => {
  const { id, token } = useParams();
  const { loading, request } = useAxios();
  const navigate = useNavigate();

  const handleSubmit = async () => {
    const response = await request({
      url: API_URLS.ACCOUNT_VERIFICATION_URL,
      method: "POST",
      data: { id, token },
    });

    if (!response.status) {
      console.log(response.error.message);
      navigate("/");
      return message.error(response.error.message);
    }
    message.success(response.success.message);
    navigate("/");
  };
  return (
    <Result
      icon={<MailOutlined style={{ fontSize: "48px", color: "#1890ff" }} />}
      title="Verify Your Email Address"
      subTitle={
        <>
          <Paragraph>
            To complete your registration, please verify your email address by
            clicking on the button below.
          </Paragraph>
        </>
      }
      extra={
        <Button type="primary" onClick={handleSubmit}>
          {loading ? (
            <Spin indicator={<LoadingOutlined spin color="white" />} />
          ) : (
            " Verify Email"
          )}
        </Button>
      }
    />
  );
};

export default EmailVerify;
