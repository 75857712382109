/*
 * Copyright © 2023-2025 www.4bex.com
 *
 * Licensed under the www.4bex.com License, Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.4bex.com/license
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React, { useEffect, useState } from "react";
import { Button, Form, InputNumber, message } from "antd";
import "./PriceForm.css";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { updateServiceCharge } from "../../../common/api/prices";

const PriceForm = ({ prices }) => {
  const [isEditing, setIsEditing] = useState(false); // State to toggle edit mode
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue(prices);
  }, [prices, form]);

  // Handle Edit Button Click
  const handleEdit = () => {
    setIsEditing(true); // Enable editing
  };

  // Handle Save Button Click
  const handleSave = () => {
    form
      .validateFields()
      .then((values) => {
        updateStoragePricingFunc.mutate({
          hourly_storage_charge: values.hourly_storage_charge,
          hourly_storage_payout: values.hourly_storage_payout,
          hourly_service_charge: values.hourly_service_charge,
          daily_storage_charge: values.daily_storage_charge,
          daily_storage_payout: values.daily_storage_payout,
          daily_service_charge: values.daily_service_charge,
        });
      })
      .catch((info) => {
        message.error(info);
        console.log("Validation Failed:", info);
      });
  };

  const queryClient = useQueryClient();

  const updateStoragePricingFunc = useMutation({
    mutationFn: updateServiceCharge,
    onSuccess: (data) => {
      message.success(data.data.success.message).then(() => {
        queryClient.invalidateQueries(["prices"], { exact: true });
        setIsEditing(false);
      });
    },
    onError: (error) => {
      message.error(error.response.data.error.message);
    },
  });

  return (
    <div style={{ maxWidth: "100%" }}>
      {prices && (
        <Form form={form} layout="vertical" initialValues={prices}>
          {/* Daily Rate Field */}
          <Form.Item
            label="Total Daily Rate"
            name="daily_storage_charge"
            rules={[
              { required: true, message: "Total Daily rate is required!" },
            ]}
          >
            {!isEditing ? (
              <div>
                £{Number(prices.daily_storage_charge)?.toFixed(2) || "0.00"}
              </div> // Show label
            ) : (
              <InputNumber
                className="price-input"
                min={1}
                formatter={(value) =>
                  `£ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                } // Format as currency
                parser={(value) => value.replace(/£\s?|(,*)/g, "")} // Parse back to numeric value
              />
            )}
          </Form.Item>
          {/* Hourly Rate Field */}
          <Form.Item
            label="Total Hourly Rate"
            name="hourly_storage_charge"
            rules={[
              { required: true, message: "Total Hourly rate is required!" },
            ]}
          >
            {!isEditing ? (
              <div>
                £{Number(prices.hourly_storage_charge)?.toFixed(2) || "0.00"}
              </div>
            ) : (
              <InputNumber
                className="price-input"
                min={1}
                formatter={(value) =>
                  `£ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                } // Format as currency
                parser={(value) => value.replace(/£\s?|(,*)/g, "")} // Parse back to numeric value
              />
            )}
          </Form.Item>
          <hr />
          <Form.Item
            label="Storage Daily Rate"
            name="daily_storage_payout"
            rules={[
              { required: true, message: "Storage Daily rate is required!" },
            ]}
          >
            {!isEditing ? (
              <div>
                £{Number(prices.daily_storage_payout)?.toFixed(2) || "0.00"}
              </div> // Show label
            ) : (
              <InputNumber
                className="price-input"
                min={1}
                formatter={(value) =>
                  `£ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                } // Format as currency
                parser={(value) => value.replace(/£\s?|(,*)/g, "")} // Parse back to numeric value
              />
            )}
          </Form.Item>
          {/* Hourly Rate Field */}
          <Form.Item
            label="Storage Hourly Rate"
            name="hourly_storage_payout"
            rules={[
              { required: true, message: "Storage Hourly rate is required!" },
            ]}
          >
            {!isEditing ? (
              <div>
                £{Number(prices.hourly_storage_payout)?.toFixed(2) || "0.00"}
              </div>
            ) : (
              <InputNumber
                className="price-input"
                min={1}
                formatter={(value) =>
                  `£ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                } // Format as currency
                parser={(value) => value.replace(/£\s?|(,*)/g, "")} // Parse back to numeric value
              />
            )}
          </Form.Item>
          <hr />
          <Form.Item label="Daily Service Charge" name="daily_service_charge">
            {!isEditing ? (
              <div>
                £{Number(prices.daily_service_charge)?.toFixed(2) || "0.00"}
              </div>
            ) : (
              <InputNumber
                className="price-input"
                min={0}
                formatter={(value) =>
                  `£ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                } // Format as currency
                parser={(value) => value.replace(/£\s?|(,*)/g, "")} // Parse back to numeric value
              />
            )}
          </Form.Item>{" "}
          <Form.Item label="Hourly Service Charge" name="hourly_service_charge">
            {!isEditing ? (
              <div>
                £{Number(prices.hourly_service_charge)?.toFixed(2) || "0.00"}
              </div>
            ) : (
              <InputNumber
                className="price-input"
                min={0}
                formatter={(value) =>
                  `£ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                } // Format as currency
                parser={(value) => value.replace(/£\s?|(,*)/g, "")} // Parse back to numeric value
              />
            )}
          </Form.Item>
          {/* Action Buttons */}
          <Form.Item style={{ paddingTop: 15 }}>
            {!isEditing ? (
              //
              <Button onClick={handleEdit}>Edit</Button>
            ) : (
              //
              <Button onClick={handleSave} htmlType="submit">
                Save
              </Button>
            )}
          </Form.Item>
        </Form>
      )}
    </div>
  );
};

export default PriceForm;
