/*
 * Copyright © 2023-2024 www.4bex.com
 *
 * Licensed under the www.4bex.com License, Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.4bex.com/license
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React, {useEffect, useState} from "react";
import {Button, Form, Grid, message, Modal, Steps, theme} from "antd";
import "./CreateStore.css";
import InitialStep from "../step/InitialStep";
import SecondStep from "../step/SecondStep";
import FourthStep from "../step/FourthStep";
import moment from "moment/moment";
import dayjs from "dayjs";
import ThirdStep from "../step/ThirdStep";
import FinalStep from "../step/FinalStep";
import {useMutation, useQueryClient} from "@tanstack/react-query";
import {CreateStorage} from "../../../../common/api/storage";

const {useBreakpoint} = Grid;

const daysOfWeek = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];

const CreateStore = ({isModalOpen, setIsModalOpen}) => {
  const [form] = Form.useForm();
  const [formData, setFormData] = useState({
    // id: id,
    title: "",
    subTitle: "",
    address1: "",
    address2: "",
    postalCode: "",
    country: "",
    longitude: 1,
    latitude: 1,
    description: "",
    storeCapacity: 1,
    // isDaily: true,
    // dailyRate: 1,
    // isHourly: true,
    // hourlyRate: 1,
    contactName: "",
    contactEmail: "",
    contactMobile: "",
    contactWebsite: "",
    openingHours: {},
    registrationNumber: "",
    city: "",
  });

  const queryClient = useQueryClient();
  const createPostMutation = useMutation({
    mutationFn: CreateStorage,
    onSuccess: (data) => {
      queryClient.invalidateQueries(["storage"], {exact: true});
      message.success(data.data.success.message);
      setIsModalOpen(false);
    },
  });

  function handleSubmit(e) {
    e.preventDefault();
    createPostMutation.mutate({
      ...formData,
      address: {
        address1: formData.address1 || "",
        address2: formData.address2 + " " + formData.city || "",
        country: formData.country || "",
        postalCode: formData.postalCode || "",
      },
    });
  }

  const screens = useBreakpoint();


  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const {token} = theme.useToken();
  const [current, setCurrent] = useState(0);

  // setting values to formData on next step
  const next = async () => {
    try {
      if (current === 3) {
        validateDates();
      } else {
        await form.validateFields().then((values) => {
          // Save current step data
          setFormData((prev) => ({
            ...prev,
            ...values,
          }));
          setCurrent(current + 1);
        });
      }
    } catch (errorInfo) {
      console.log("Validation Failed:", errorInfo);
      // This will stop navigation to the next step if validation fails
    }
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  // =======================================================

  const defaultOpenTime = dayjs("08:00:00", "HH:mm:ss");
  const defaultCloseTime = dayjs("17:00:00", "HH:mm:ss");

  const [days, setDays] = useState(
    daysOfWeek.reduce((acc, day) => {
      acc[day] = {
        open: false,
        hours: [{openTime: defaultOpenTime, closeTime: defaultCloseTime}],
      };
      return acc;
    }, {})
  );

  const calculateTotalHours = () => {
    let totalHours = 0;
    for (const day of daysOfWeek) {
      if (days[day].open) {
        days[day].hours.forEach(({openTime, closeTime}) => {
          if (openTime && closeTime) {
            const diff = moment.duration(closeTime.diff(openTime)).asHours();
            totalHours += diff;
          }
        });
      }
    }
    return totalHours;
  };

  // const formatTimesWithDate = (time, date = new Date().getDate()) => {
  //   return time
  //     ? moment(`${date} ${time.format("HH:mm:ss")}`).format(
  //         "YYYY-MM-DD HH:mm:ss"
  //       )
  //     : null;
  // };

  const extractTime = (dateTime) => {
    return dateTime ? new Date(dateTime).toTimeString().split(" ")[0] : null;
  };

  // Function to update old dates with new dates
  const updateDates = (oldDates, newDatesTime) => {
    for (const day in oldDates) {
      if (oldDates[day]) {
        if (oldDates[day].open) {
          // Update hours with only the time part from new dates
          oldDates[day].hours = newDatesTime[day].map(
            ({openTime, closeTime}) => ({
              openTime: extractTime(openTime),
              closeTime: extractTime(closeTime),
            })
          );
        } else {
          // If open is false, set hours to null
          oldDates[day].hours = null;
        }
      }
    }
  };

  const validateHours = () => {
    for (const day of daysOfWeek) {
      if (days[day].open) {
        const totalDayHours = days[day].hours.reduce(
          (sum, {openTime, closeTime}) => {
            if (openTime && closeTime) {
              const diff = moment.duration(closeTime.diff(openTime)).asHours();
              return sum + diff;
            }
            return sum;
          },
          0
        );

        if (totalDayHours < 6) {
          message.error(`${day} must have a minimum of 6 open hours.`);
          return false;
        }
      }
    }

    const totalHours = calculateTotalHours();
    if (totalHours < 40) {
      message.error("The total weekly open hours must be at least 40 hours.");
      return false;
    }
    return true;
  };

  const validateDates = () => {
    if (validateHours()) {
      const formattedDays = {};
      const newDates = JSON.parse(JSON.stringify(days));
      for (const day of daysOfWeek) {
        if (newDates[day].open) {
          formattedDays[day] = days[day].hours.map(
            ({openTime, closeTime}) => ({
              openTime: openTime,
              closeTime: closeTime,
            })
          );
        }
      }
      message.success("Business hours saved successfully!");
      updateDates(newDates, formattedDays);
      setFormData((prev) => ({
        ...prev,
        openingHours: {...prev.openingHours, ...newDates},
      }));
    }
  };

  useEffect(() => {
    if (Object.keys(formData?.openingHours).length > 0) {
      setCurrent((prev) => prev + 1);
    }
  }, [formData?.openingHours]);

  // =======================================================

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };


  const steps = [
    {
      title: "Location",
      content: (
        <InitialStep
          form={form}
          formData={formData}
          handleChange={handleChange}
        />
      ),
    },
    {
      title: "Store Name",
      content: <SecondStep/>,
    },
    {
      title: "Contact Info",
      content: <ThirdStep/>,
    },
    {
      title: "Open Hours",
      content: <FourthStep days={days} setDays={setDays}/>,
    },
    {
      title: "Review",
      content: (
        <FinalStep storeInfo={formData} businessHours={formData.openingHours}/>
      ),
    },
  ];

  const contentStyle = {
    lineHeight: "260px",
    textAlign: "center",
    color: token.colorTextTertiary,
    backgroundColor: token.colorFillAlter,
    borderRadius: token.borderRadiusLG,
    // border: `1px solid ${token.colorBorder}`,
    marginTop: 16,
  };

  return (
    <div>
      <Modal
        className="add-store-modal"
        title={<div style={{textAlign: "center"}}>Add Store</div>}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
      >
        <div>
          <Steps
            className="add-store-steps"
            current={current}
            items={steps}
            labelPlacement={"vertical"}
            direction={
              screens.lg
                ? "horizontal"
                : screens.md
                  ? "vertical"
                  : screens.sm
                    ? "vertical"
                    : "vertical"
            }
          />
          <Form layout={"vertical"} form={form} initialValues={formData}>
            <div style={contentStyle}>{steps[current].content}</div>
          </Form>
          <div
            style={{
              marginTop: 24,
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            {current > 0 && (
              <Button
                style={{
                  margin: "0 8px",
                }}
                onClick={() => prev()}
              >
                Previous
              </Button>
            )}
            {current < steps.length - 1 && (
              <Button type="primary" onClick={() => next()}>
                Next
              </Button>
            )}
            {current === steps.length - 1 && (
              <Button type="primary" onClick={handleSubmit}>
                Done
              </Button>
            )}
          </div>
        </div>
      </Modal>
    </div>
  );
};
export default CreateStore;
