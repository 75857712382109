import { API_URLS } from "../configs/api.urls";
import { axiosPrivate } from "../configs/axios";

export const getAllServiceCharge = async () => {
  return await axiosPrivate({
    url: API_URLS.GET_ALL_SERVICE_CHARGES_URL,
    method: "GET",
  });
};

export const updateServiceCharge = async (data) => {
  return await axiosPrivate({
    url: API_URLS.POST_STORAGE_PRICING_URL,
    method: "POST",
    data: data,
  });
};
