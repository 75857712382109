/*
 * Copyright © 2023-2024 www.4bex.com
 *
 * Licensed under the www.4bex.com License, Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.4bex.com/license
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { useQuery } from "@tanstack/react-query";
import { Button, Skeleton, Space, Tag } from "antd";
import moment from "moment/moment";
import React, { useState } from "react";
import { getAllTransactions } from "../../../common/api/transaction";
import TableAntd from "../../../common/components/table/TableAntd";
import {
  bookingStatusArray,
  paymentCategoryArray
} from "../../../common/constant/Constants";
import SearchComponent from "../../../common/search/Search";
import TransactionModal from "./transactionModal/TransactionModal";

const Transaction = () => {
  const [params, setParams] = useState({
    page: 1,
    limit: 10,
  });
  const [modalVisible, setModalVisible] = useState(false);
  const [currentTransaction, setCurrentTransaction] = useState(null);

  const transactionQuery = useQuery({
    queryKey: ["transaction", "all", params],
    queryFn: () => getAllTransactions(params),
    staleTime: 1000 * 60 * 15, // 15 minutes
  });

  const transactionData = transactionQuery.data?.data?.success.data;
  const transactionMeta = transactionQuery.data?.data?.success.meta;

  const handleViewTransaction = (transaction) => {
    setCurrentTransaction(transaction);
    setModalVisible(true);
  };

  // Handle closing modal
  const handleCloseModal = () => {
    setModalVisible(false);
    setCurrentTransaction(null);
  };

  const columns = [
    {
      title: "Store Name",
      dataIndex: "storage_name",
      key: "storage_name",
    },
    {
      title: "Payment Category",
      dataIndex: "category",
      key: "category",
    },
    {
      title: "Status",
      key: "transaction_status",
      dataIndex: "transaction_status",
      render: (_, { transaction_status }) => (
        <Tag
          color={
            transaction_status === "pending"
              ? "yellow"
              : transaction_status === "succeeded"
              ? "green"
              : "volcano"
          }
          key={transaction_status}
        >
          {transaction_status.toUpperCase()}
        </Tag>
      ),
    },
    {
      title: "Transaction Date",
      dataIndex: "transaction_day",
      key: "transaction_day",
      render: (_, { transaction_day }) => {
        return moment(transaction_day).format("YYYY/MM/DD");
      },
    },
    {
      title: "Total Amount",
      dataIndex: "transaction_amount",
      key: "transaction_amount",
    },
    {
      title: "Action",
      key: "action",
      render: (_, data) => (
        <Space size="middle">
          <Button onClick={() => handleViewTransaction(data)}>View</Button>
        </Space>
      ),
    },
  ];

  const handleTableChange = (pagination, filters, sorter) => {
    setParams({
      ...params,
      page: pagination.current,
      limit: pagination.pageSize,
    });
  };

  const searchFilters = [
    { key: "title", label: "Store Name", type: "input" },
    {
      key: "category",
      label: "Payment Category",
      type: "select",
      optionArray: paymentCategoryArray,
    },
    {
      key: "status",
      label: "Payment Status",
      type: "select",
      optionArray: bookingStatusArray,
    },
  ];

  const handleSearch = async (filterCriteria) => {
    setParams({ ...params, page: 1, ...filterCriteria });
  };

  return (
    <div>
      <div className="booking-container">
        <div>
          <SearchComponent filters={searchFilters} onSearch={handleSearch} />
        </div>

        {currentTransaction && (
          <TransactionModal
            transaction={currentTransaction}
            visible={modalVisible}
            onClose={handleCloseModal}
          />
        )}
        <div className="booking-table">
          {transactionQuery.isLoading ? (
            <Skeleton />
          ) : (
            <TableAntd
              columns={columns}
              data={transactionData}
              total={transactionMeta?.totalCount}
              handleTableChange={handleTableChange}
              current={params?.page}
              pageSize={params?.limit}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Transaction;
