/*
 * Copyright © 2023-2024 www.4bex.com
 *
 * Licensed under the www.4bex.com License, Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.4bex.com/license
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { useQuery } from "@tanstack/react-query";
import { Button, Skeleton, Space } from "antd";
import React, { useState } from "react";
import { getTransactionsNeedToPay } from "../../../common/api/transaction";
import TableAntd from "../../../common/components/table/TableAntd";
import SearchComponent from "../../../common/search/Search";
import CreatePayoutModal from "./createPayoutModal/CreatePayoutModal";
import PayoutModal from "./payoutModal/PayoutModal";

const Payout = () => {
  const [params, setParams] = useState({
    page: 1,
    limit: 10,
  });
  const [modalVisible, setModalVisible] = useState(false);
  const [createModalVisible, setCreateModalVisible] = useState(false);
  const [currentTransaction, setCurrentTransaction] = useState(null);

  const payoutQuery = useQuery({
    queryKey: ["payout", "all", params],
    queryFn: () => getTransactionsNeedToPay(params),
    staleTime: 1000 * 60 * 15, // 15 minutes
  });

  const payoutData = payoutQuery.data?.data?.success.data;
  const payoutMeta = payoutQuery.data?.data?.success.meta;

  const handleViewTransaction = (transaction) => {
    setCurrentTransaction(transaction);
    setModalVisible(true);
  };
  const handlePayTransaction = (transaction) => {
    setCurrentTransaction(transaction);
    setCreateModalVisible(true);
  };

  // Handle closing modal
  const handleCloseModal = () => {
    setModalVisible(false);
    setCreateModalVisible(false);
    setCurrentTransaction(null);
  };

  const columns = [
    {
      title: "User Name",
      dataIndex: "user_name",
      key: "user_name",
    },
    {
      title: "Store Name",
      dataIndex: "storage_name",
      key: "storage_name",
    },
    {
      title: "Total Bookings",
      dataIndex: "total_bookings",
      key: "total_bookings",
    },
    {
      title: "Total Amount",
      dataIndex: "total_storage_charge",
      key: "total_storage_charge",
    },
    {
      title: "Action",
      key: "action",
      render: (_, data) => (
        <Space size="middle">
          <Button onClick={() => handleViewTransaction(data)}>View</Button>
          <Button onClick={() => handlePayTransaction(data)}>Pay</Button>
        </Space>
      ),
    },
  ];

  const handleTableChange = (pagination) => {
    setParams({
      ...params,
      page: pagination.current,
      limit: pagination.pageSize,
    });
  };

  const searchFilters = [
    { key: "userName", label: "User Name", type: "input" },
    { key: "storeName", label: "Store Name", type: "input" },
  ];

  const handleSearch = async (filterCriteria) => {
    setParams({ ...params, page: 1, ...filterCriteria });
  };

  return (
    <div>
      <div className="booking-container">
        <div>
          <SearchComponent filters={searchFilters} onSearch={handleSearch} />
        </div>

        {currentTransaction && modalVisible && (
          <PayoutModal
            transaction={currentTransaction}
            visible={modalVisible}
            onClose={handleCloseModal}
          />
        )}
        {currentTransaction && createModalVisible && (
          <CreatePayoutModal
            transaction={currentTransaction}
            visible={createModalVisible}
            onClose={handleCloseModal}
          />
        )}
        <div className="booking-table">
          {payoutQuery.isLoading ? (
            <Skeleton />
          ) : (
            <TableAntd
              columns={columns}
              data={payoutData}
              total={payoutMeta?.totalCount}
              handleTableChange={handleTableChange}
              current={params?.page}
              pageSize={params?.limit}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Payout;
