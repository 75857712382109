/*
 * Copyright © 2023-2024 www.4bex.com
 *
 * Licensed under the www.4bex.com License, Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.4bex.com/license
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Col, Divider, message, Row, Tag, Typography } from "antd";
import React from "react";
import { updateStorageStatus } from "../../../common/api/storage";

const { Title, Text } = Typography;

const SingleBookingModal = ({ storeInfo, setIsModalVisible }) => {
  const queryClient = useQueryClient();
  const StorageStatusMutation = useMutation({
    mutationFn: updateStorageStatus,
    onSuccess: (data) => {
      queryClient.invalidateQueries(["storage", "all"], { exact: true });
      message.success(data.data.success.message);
      setIsModalVisible(false);
    },
    onError: (error) => {
      message.error(error.data.error.message);
    },
  });

  return (
    <div>
      <div style={{ padding: "20px" }}>
        <Title
          level={3}
          style={{
            textAlign: "center",
            marginBottom: "20px",
            borderBottom: "1px solid #000",
          }}
        >
          Booking Details
        </Title>
        <Row style={{ textAlign: "start", lineHeight: "5px" }} gutter={[16, 8]}>
          <Col span={10}>
            <Text strong>Store Name:</Text>
          </Col>
          <Col span={14}>
            <Text>{storeInfo?.title}</Text>
          </Col>
          <Col span={10}>
            <Text strong>Drop Off Date:</Text>
          </Col>
          <Col span={14}>
            <Text>{storeInfo.drop_off_date}</Text>
          </Col>
          <Col span={10}>
            <Text strong>Pick Up Date:</Text>
          </Col>
          <Col span={14}>
            <Text>{storeInfo.pick_up_date}</Text>
          </Col>

          <Col span={10}>
            <Text strong>Booking Type:</Text>
          </Col>
          <Col span={14}>
            <Text>{storeInfo.booking_type}</Text>
          </Col>

          <Col span={10}>
            <Text strong>Booking Status:</Text>
          </Col>
          <Col span={14}>
            <Tag
              color={
                storeInfo.status === "pending"
                  ? "yellow"
                  : storeInfo.status === "completed"
                  ? "green"
                  : "volcano"
              }
              key={storeInfo.status}
            >
              {storeInfo.status.toUpperCase()}
            </Tag>
          </Col>

          <Col span={10}>
            <Text strong>Payment Status:</Text>
          </Col>
          <Col span={14}>
            <Tag
              color={
                storeInfo.payment_status === "paid"
                  ? "green"
                  : storeInfo.payment_status === "pending"
                  ? "yellow"
                  : "volcano"
              }
              key={storeInfo.payment_status}
            >
              {storeInfo.payment_status.toUpperCase()}
            </Tag>
          </Col>
        </Row>

        <Divider />

        <Title level={4}>Pricing</Title>

        <Row style={{ textAlign: "start", lineHeight: "5px" }} gutter={[16, 8]}>
          <Col span={10}>
            <Text strong>Booking Charges</Text>
          </Col>
          <Col span={8}>
            <Text>{`£${storeInfo?.storage_charge}`}</Text>
          </Col>
          <Col span={10}>
            <Text strong>Service Charges:</Text>
          </Col>
          <Col span={14}>
            <Text>{`£${storeInfo.service_charge}`}</Text>
          </Col>
          <Col span={10}>
            <Text strong>Total Chargers:</Text>
          </Col>
          <Col span={14}>
            <Text>{`£${storeInfo.total_charge}`}</Text>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default SingleBookingModal;
