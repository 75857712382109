/*
 * Copyright © 2023-2024 www.4bex.com
 *
 * Licensed under the www.4bex.com License, Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.4bex.com/license
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React from "react";
import "./Home.css";
import { Button, Card, Col, Row } from "antd";
import nashvilleImg from "../../assests/images/nashville.jpg";
import southHavenImg from "../../assests/images/nashville.jpg";
import stantonImg from "../../assests/images/nashville.jpg";
import newBuffaloImg from "../../assests/images/nashville.jpg";
import SearchBar from "../../common/components/search-bar/SearchBar";

const Home = () => {
  const locations = [
    { name: "Nashville", distance: "53 miles away", image: nashvilleImg },
    { name: "South Haven", distance: "168 miles away", image: southHavenImg },
    { name: "Stanton", distance: "192 miles away", image: stantonImg },
    { name: "New Buffalo", distance: "130 miles away", image: newBuffaloImg },
  ];

  const LocationGrid = ({ locations }) => (
    <Row gutter={[16, 16]}>
      <div className="location-header">
        <h1>Our Best Luggage Storage Locations</h1>
      </div>
      {locations.map(({ name, image, distance }, index) => (
        <Col xs={24} sm={12} md={6} key={index}>
          <Card cover={<img alt={name} src={image} />} hoverable>
            <Card.Meta title={name} description={distance} />
          </Card>
        </Col>
      ))}
    </Row>
  );

  return (
    <div className="home">
      <div className="banner-container">
        <div className="banner-overlay">
          <Row className="banner-content" justify="start" align="bottom">
            <Col className="banner-text">
              <h1>Convenient Luggage Storage Near You!</h1>
              <p>
                Store your bags safely at thousands of trusted locations,
                including hotels and shops, available wherever you go. Secure,
                easy, and stress-free storage at your fingertips.
              </p>
              <div className="banner-image"></div>
            </Col>
          </Row>
          {/*<Row className="banner-btn" justify="start">*/}
          {/*  <Col xs={24} md={12}>*/}
          {/*    <Button type="primary" size="large">See More</Button>*/}
          {/*  </Col>*/}
          {/*</Row>*/}
        </div>
      </div>

      <div className="Other">
        <SearchBar />
      </div>

      {/* <div>
        <LocationGrid locations={locations}/>
      </div> */}

      <div className="bottom-banner-container">
        <div className="bottom-banner-overlay">
          <div className="question-text">
            <h1>Questions About Luggage Storing?</h1>
          </div>
          <div className="question-button">
            <Button className="question-btn" type="primary" size="large">
              Ask Superhost
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
