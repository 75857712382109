/*
 * Copyright © 2023-2024 www.4bex.com
 *
 * Licensed under the www.4bex.com License, Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.4bex.com/license
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React, { useState } from "react";
import {Alert, Col, Empty, Row, Skeleton} from "antd";
import DropdownOption from "../../../common/components/dropdown-option/DropdownOption";
import UserBooking from "../bookingcard/UserBooking";
import CreateStore from "../store/create/CreateStore";
import { useQuery } from "@tanstack/react-query";
import { getBookingByUserId } from "../../../common/api/booking";
import { getStorageByUserId } from "../../../common/api/storage";
import moment from "moment";
import {formatAddress} from "../../../common/util";

const PartnerDashboard = ({setActiveTabKey}) => {
  const bookingQuery = useQuery({
    queryKey: ["bookings", "user"],
    queryFn: getBookingByUserId,
    staleTime: 1000 * 60 * 15, // 15 minutes
  });

  const storageQuery = useQuery({
    queryKey: ["storage"],
    queryFn: getStorageByUserId,
    staleTime: 1000 * 60 * 15, // 15 minutes
  });

  const bookings = bookingQuery.data?.data?.success.data.slice(0, 5);

  const stores = storageQuery.data?.data?.success.data.slice(0, 5);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const dropdownItemsForBooking = [
    {
      label: 'View More',
      key: 'viewMore',
      onClick: (() => {
        setActiveTabKey("2")
      })
    }
  ];


  const dropdownItemsForStore = [
    // {
    //   label: 'View More',
    //   key: 'viewMore',
    //   onClick: (() => {
    //     setActiveTabKey("4")
    //   })
    // },
    {
      label: 'Add Store',
      key: 'addStore',
      onClick: (() => {
        showModal()
      })
    }
  ];



  return (
    <div className="partner-dashboard-container">
      <div className="booking-dcontainer">
        <Row>
          <Col span={20}>
            <h2>My Bookings</h2>
          </Col>
          <Col
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
            span={4}
          >
            <div>
              <DropdownOption items={dropdownItemsForBooking} />
              <CreateStore
                isModalOpen={isModalOpen}
                setIsModalOpen={setIsModalOpen}
              />
            </div>
          </Col>
          <Col span={24}>
            <div>
              {bookingQuery.isLoading ? (
                <Skeleton />
              ) : bookingQuery.isError ? (
                <Empty />
              ) : (
                bookings?.length > 0 ?
                bookings?.map((booking, index) => (
                  <UserBooking
                    key={index}
                    name={booking.title}
                    status={booking.status}
                    luggageCount={booking.luggage_size}
                    dateOrLocation={moment(booking.drop_off_date).format(
                      "YYYY/MM/DD"
                    )}
                  />
                )) :
                  <Alert
                    message="Info"
                    description="There are no bookings associated with this account"
                    type="info"
                    showIcon
                  />
              )}
            </div>
          </Col>
        </Row>

        <Row>
          <Col span={20}>
            <h2>My Stores</h2>
          </Col>
          <Col
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
            span={4}
          >
            <div>
              { stores?.length > 0 ? null : <DropdownOption setIsModalOpen={setIsModalOpen} items={dropdownItemsForStore} />}
            </div>
          </Col>
          <Col span={24}>
            <div>
              {storageQuery.isLoading ? (
                <Skeleton />
              ) : storageQuery.data?.data?.success.data.length === 0 ? (
                <Alert
                  message="Info"
                  description="There are no stored associated with this account."
                  type="info"
                  showIcon
                />
              ) : (
                stores.map((store, index) => (
                  <UserBooking
                    key={index}
                    name={store.title}
                    status={store.status}
                    luggageCount={store.capacity}
                    frequency={store.opening_hours}
                    dateOrLocation={formatAddress(store.address)}
                  />
                ))
              )}
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default PartnerDashboard;
