/*
 * Copyright © 2023-2024 www.4bex.com
 *
 * Licensed under the www.4bex.com License, Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.4bex.com/license
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import {axiosPrivate} from "../configs/axios";
import {API_URLS} from "../configs/api.urls";

export const submitContactUs = async (data) => {
  return axiosPrivate({
    url: API_URLS.CONTACT_US_URL,
    method: "POST",
    data: data,
  });
};