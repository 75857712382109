/*
 * Copyright © 2023-2024 www.4bex.com
 *
 * Licensed under the www.4bex.com License, Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.4bex.com/license
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React, {useState} from 'react';
import FourthStep from "../store/step/FourthStep";
import dayjs from "dayjs";
import {message, Modal} from "antd";
import {daysOfWeek} from "../../../common/util";
import {useMutation} from "@tanstack/react-query";
import {updateStorageOpenHours} from "../../../common/api/storage";

const convertToDayjs = (data) => {
  const converted = {};
  // default time value set for input box if store is closed
  const defaultOpenTime = dayjs("08:00:00", "HH:mm:ss");
  const defaultCloseTime = dayjs("17:00:00", "HH:mm:ss");
  for (const [day, details] of Object.entries(data)) {
    converted[day] = {
      ...details,
      hours: details.open ? details.hours.map((hour) => ({
        openTime: dayjs(hour.openTime, "HH:mm:ss"),
        closeTime: dayjs(hour.closeTime, "HH:mm:ss"),
      })) : [{openTime: defaultOpenTime, closeTime: defaultCloseTime}],
    };
  }
  return converted;
};

const EditOpenHours = ({openHours, isEditOpenHours, setOpeningHours, setEditModal,storageId}) => {

  const [days, setDays] = useState(() => convertToDayjs(openHours));

  const normalizeTimeFormat = (time) => {
    if (!time) return null; // Handle null cases
    if (typeof time === "string") {
      return dayjs(time, "HH:mm:ss").format("HH:mm:ss"); // Convert string to "HH:mm:ss"
    }
    if (dayjs.isDayjs(time)) {
      return time.format("HH:mm:ss"); // Convert Day.js object to "HH:mm:ss"
    }
    return time; // Return as is if already normalized
  };

  const logChangeDateTime = () => {
    let formattedDays = {};
    for (const day of daysOfWeek) {
      formattedDays[day] = {};
      if (days[day].open) {
        formattedDays[day].hours = days[day].hours.map(
          ({openTime, closeTime}) => ({
            openTime: normalizeTimeFormat(openTime),
            closeTime: normalizeTimeFormat(closeTime),
          })
        );
      }
      formattedDays[day].open = days[day].open;
    }
    setOpeningHours(formattedDays);
    updateOpenHours.mutate({
      id: storageId,
      data: {
        openingHours: formattedDays
      },
    });
  }

  const handleCancel = () => {
    setEditModal(false);
  };

  const updateOpenHours = useMutation({
    mutationFn: ({id, data}) => updateStorageOpenHours(id, data),
    onSuccess: (data) => {
      handleCancel();
      message.success(data.data.success.message);
    },
    onError: (error) => {
      message.error(error.response.data.error.message);
    },
  });

  return (
    <div>
      <Modal open={isEditOpenHours} onOk={logChangeDateTime} onCancel={handleCancel} confirmLoading={updateOpenHours.isPending}>
        <FourthStep setDays={setDays} days={days}/>
      </Modal>
    </div>
  );
};

export default EditOpenHours;