/*
 * Copyright © 2023-2024 www.4bex.com
 *
 * Licensed under the www.4bex.com License, Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.4bex.com/license
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React from "react";
import {Route, Routes} from "react-router-dom";
import Home from "./page/home";
import GlobalLayout from "./page/globalLayout/GlobalLayout";
import RequireAuth from "./page/requireAuth/RequireAuth";
import Unauthorized from "./page/unauthorized/Unauthorized";
import Partner from "./page/partner";
import {ReactQueryDevtools} from "@tanstack/react-query-devtools";
import MyAccount from "./common/components/myaccount/MyAccount";
import Book from "./page/booking";
import EmailVerify from "./page/emailVerify/EmailVerify";
import StoreView from "./page/booking/store/StoreView";
import ContactUs from "./page/contactUs/ContactUsForm/ContactUs";
import Admin from "./page/admin/Admin";
import PaymentStatus from "./page/booking/paymentStatus/PaymentStatus";
import NotFound from "./common/components/not-found/NotFound";
import TermsAndConditions from "./page/termsAndConditions/TermsAndConditions";

const ROLES = {
  User: "user",
  Admin: "admin",
  Manager: "manager",
};

const Layout = () => {
  return (
    <main className="App">
      <GlobalLayout />
    </main>
  );
};

function App() {
  return (
    <div className="App">
      <Routes>
        {/* public routes */}
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="active/:id/:token" element={<EmailVerify />} />
          <Route path="/unauthorized" element={<Unauthorized />} />
          <Route path="/booking" element={<Book />} />
          <Route path="/store/:id" element={<StoreView />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/return" element={<PaymentStatus />} />
          <Route path="/terms-and-conditions" element={<TermsAndConditions />} />

          {/* protected routes */}
          <Route
            element={
              <RequireAuth
                allowedRoles={[ROLES.Admin, ROLES.User, ROLES.Manager]}
              />
            }
          >
            <Route path="account">
              <Route index element={<MyAccount />} />
            </Route>
          </Route>
          <Route
            element={
              <RequireAuth allowedRoles={[ROLES.Admin, ROLES.Manager]} />
            }
          >
            <Route path="admin">
              <Route index element={<Admin />} />
            </Route>
          </Route>
          <Route element={<RequireAuth allowedRoles={[ROLES.User]} />}>
            <Route path="user">
              <Route index element={<Partner />} />
            </Route>
          </Route>
          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
      <ReactQueryDevtools />
    </div>
  );
}

export default App;
