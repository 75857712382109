import { API_URLS } from "../configs/api.urls";
import { axiosPrivate } from "../configs/axios";

export const makePayout = async (data) => {
  return await axiosPrivate({
    url: API_URLS.POST_PAYOUT_TRANSACTION_URL,
    method: "Post",
    data: data,
  });
};
export const getAllTransactions = async (queryParams) => {
  return await axiosPrivate({
    url: API_URLS.GET_ALL_TRANSACTION_URL,
    method: "GET",
    params: queryParams,
  });
};

export const getTransactionsNeedToPay = async (queryParams) => {
  return await axiosPrivate({
    url: API_URLS.GET_PAYOUT_TRANSACTION_URL,
    method: "GET",
    params: queryParams,
  });
};

export const GetTransactionsByUserId = async (queryParams) => {
  return await axiosPrivate({
    url: API_URLS.GET_USER_TRANSACTION_URL,
    method: "GET",
    params: queryParams,
  });
};

export const getBookingTransactionsByUserId = async (userId) => {
  return await axiosPrivate({
    url: API_URLS.GET_BOOKING_TRANSACTION_URL + userId,
    method: "GET",
  });
};
