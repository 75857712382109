/*
 * Copyright © 2023-2024 www.4bex.com
 *
 * Licensed under the www.4bex.com License, Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.4bex.com/license
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React from 'react';
import {Col, Divider, Row, Typography} from "antd";

const { Title, Text } = Typography;

const SingleBookingModal = ({data}) => {
  return (
    <div>
      <div style={{padding: "20px"}}>
        <Title
          level={3}
          style={{
            textAlign: "center",
            marginBottom: "20px",
            borderBottom: "1px solid #000",
          }}
        >
          Booking Information
        </Title>

        {/*<Title level={4}>Booking Information</Title>*/}
        <Row style={{textAlign: "start", lineHeight: "5px"}} gutter={[16, 8]}>
          <Col span={10}>
            <Text strong>Store Name:</Text>
          </Col>
          <Col span={14}>
            <Text>{data?.title}</Text>
          </Col>

          <Col span={10}>
            <Text strong>Booking Type:</Text>
          </Col>
          <Col span={14}>
            <Text>{data?.booking_type.toString().toUpperCase()}</Text>
          </Col>

          <Col span={10}>
            <Text strong>Booking Status:</Text>
          </Col>
          <Col span={14}>
            <Text>{data?.status.toString().toUpperCase()}</Text>
          </Col>


          <Col span={10}>
            <Text strong>Pick Up Date:</Text>
          </Col>
          <Col span={14}>
            <Text>{(data?.pick_up_date).replaceAll(/[TZ]/g, " ")}</Text>
          </Col>
          <Col span={10}>
            <Text strong>Drop off Date:</Text>
          </Col>
          <Col span={14}>
            <Text>{(data.drop_off_date).replaceAll(/[TZ]/g, " ")}</Text>
          </Col>
          <Col span={10}>
            <Text strong>Luggage Count:</Text>
          </Col>
          <Col span={14}>
            <Text>{data.luggage_size}</Text>
          </Col>

          <Col span={10}>
            <Text strong>Total Charge:</Text>
          </Col>
          <Col span={14}>
            <Text>£ {data.total_charge}</Text>
          </Col>

          <Col span={10}>
            <Text strong>Payment Status:</Text>
          </Col>
          <Col span={14}>
            <Text>{data?.payment_status.toString().toUpperCase()}</Text>
          </Col>

        </Row>


        <Divider/>

      </div>
    </div>
  );
};

export default SingleBookingModal;