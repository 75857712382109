/*
 * Copyright © 2023-2024 www.4bex.com
 *
 * Licensed under the www.4bex.com License, Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.4bex.com/license
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { useQuery } from "@tanstack/react-query";
import { Button, Descriptions, Modal, Skeleton, Space, Tag } from "antd";
import moment from "moment";
import React, { useState } from "react";
import { getstoragebookingByUserId } from "../../../common/api/booking";
import TableAntd from "../../../common/components/table/TableAntd";
import {
  bookingStatusArray,
  bookingTypeArray,
} from "../../../common/constant/Constants";
import SearchComponent from "../../../common/search/Search";
import OtpForm from "./OtpForm";

const PartnerBusiness = () => {
  const [params, setParams] = useState({
    page: 1,
    limit: 10,
  });
  const [selectedBooking, setSelectedBooking] = useState(null);

  const [isModalVisible, setIsModalVisible] = useState(false);

  const storageBookingQuery = useQuery({
    queryKey: ["storageBooking", params],
    queryFn: () => getstoragebookingByUserId(params),
    staleTime: 1000 * 6 * 15,
  });

  const storageBookingData = storageBookingQuery.data?.data?.success.data;
  const storageBookingMeta = storageBookingQuery.data?.data?.success.meta;

  const handleTableChange = (pagination) => {
    setParams({
      ...params,
      page: pagination.current,
      limit: pagination.pageSize,
    });
  };

  const searchFilters = [
    { key: "customerName", label: "Customer Name", type: "input" },
    {
      key: "bookingType",
      label: "Booking Type",
      type: "select",
      optionArray: bookingTypeArray,
    },
    {
      key: "status",
      label: "Booking Status",
      type: "select",
      optionArray: bookingStatusArray,
    },
  ];

  const handleSearch = (filterCriteria) => {
    setParams({ ...params, page: 1, ...filterCriteria });
  };

  const bookingColumns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Customer Name",
      dataIndex: "full_name",
      key: "full_name",
    },
    {
      title: "Drop Off",
      dataIndex: "drop_off_date",
      key: "drop_off_date",
      render: (record) => moment(record).format("YYYY-MM-DD HH:mm:ss"),
    },
    {
      title: "Pick Up",
      dataIndex: "pick_up_date",
      key: "pick_up_date",
      render: (record) => moment(record).format("YYYY-MM-DD HH:mm:ss"),
    },
    {
      title: "Duration",
      dataIndex: "duration",
      key: "duration",
      render: (_, record) => {
        const dropOffDate = moment(record.drop_off_date);
        const pickUpDate = moment(record.pick_up_date);

        if (record.booking_type === "daily") {
          // Calculate difference in days
          const totalHours = pickUpDate.diff(dropOffDate, "hours");
          const days = Math.ceil(totalHours / 24);
          return `${days} day(s)`;
        } else if (record.booking_type === "hourly") {
          // Calculate difference in hours
          const hours = pickUpDate.diff(dropOffDate, "hours");
          return `${hours} hour(s)`;
        }
        return "-"; // Fallback if type is not recognized
      },
    },
    {
      title: "Booking Type",
      dataIndex: "booking_type",
      key: "booking_type",
      render: (record) => {
        const color = record === "hourly" ? "green" : "orange";
        return <Tag color={color}>{record.toUpperCase()}</Tag>;
      },
    },
    {
      title: "Luggage count",
      dataIndex: "luggage_size",
      key: "luggage_size",
    },
    {
      title: "Status",
      key: "status",
      dataIndex: "status",
      render: (record) => record.toString().toUpperCase(),
    },
    {
      title: "Payment Status",
      key: "payment_status",
      dataIndex: "payment_status",
      render: (record) => record.toString().toUpperCase(),
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <Button
            style={{ cursor: "pointer" }}
            onClick={() => {
              setSelectedBooking(record);
              setIsModalVisible(true);
            }}
          >
            View
          </Button>
        </Space>
      ),
    },
  ];

  return (
    <div>
      <div className="booking-container">
        <div>
          <SearchComponent filters={searchFilters} onSearch={handleSearch} />
        </div>
        <div className="booking-table">
          {storageBookingQuery.isLoading ? (
            <Skeleton />
          ) : (
            <TableAntd
              columns={bookingColumns}
              data={storageBookingData}
              total={storageBookingMeta?.totalCount}
              handleTableChange={handleTableChange}
              current={params?.page}
              pageSize={params?.limit}
            />
          )}
        </div>
      </div>

      <Modal
        // loading={loading}
        open={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        footer={null}
        width="75%"
      >
        <Descriptions title="Customer Info">
          <Descriptions.Item label="Customer Name">
            {selectedBooking?.full_name}
          </Descriptions.Item>
          <Descriptions.Item label="Mobile">
            {selectedBooking?.phone_number}
          </Descriptions.Item>
          <Descriptions.Item label="Email">
            {selectedBooking?.email_address}
          </Descriptions.Item>
        </Descriptions>
        <br />
        <OtpForm
          bookingId={selectedBooking?.id}
          bookingStatus={selectedBooking?.status}
          modelClose={() => setIsModalVisible(false)}
        />
      </Modal>
    </div>
  );
};

export default PartnerBusiness;
