/*
 * Copyright © 2023-2024 www.4bex.com
 *
 * Licensed under the www.4bex.com License, Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.4bex.com/license
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

export const formatAddress = (rawAddress) => {
  const address = JSON.parse(rawAddress);
  const { address1, address2, country, postalCode } = address;
  
  return `${address1}, ${address2}, ${country}, ${postalCode}`;
};

export const daysOfWeek = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];

export const sortWeekDays = (openHours) =>{
  return Object.keys(openHours)
    .sort((a, b) => daysOfWeek.indexOf(a) - daysOfWeek.indexOf(b))
    .reduce((sortedObj, day) => {
      sortedObj[day] = openHours[day];
      return sortedObj;
    }, {});
}

export const formatHours = (hours) => {
  if (!hours || hours.length === 0) return "Closed";
  return hours
    .map(({ openTime, closeTime }) => `${openTime} - ${closeTime}`)
    .join("\n");
};