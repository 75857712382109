/*
 * Copyright © 2023-2025 www.4bex.com
 *
 * Licensed under the www.4bex.com License, Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.4bex.com/license
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React, { useState } from "react";
import { Form, Input, Button, Checkbox, message, Descriptions } from "antd";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { updateBookingStatus } from "../../../common/api/booking";

const OtpForm = ({ bookingId, bookingStatus, modelClose }) => {
  const [customerNoShow, setCustomerNoShow] = useState(false);

  const queryClient = useQueryClient();

  const UpdateBookingStatusMutation = useMutation({
    mutationFn: ({ id, data }) => updateBookingStatus(id, data),
    onSuccess: (data) => {
      message.success(data.data.success.message);
      queryClient.invalidateQueries(["storageBooking"], { exact: true });
      modelClose();
    },
    onError: (error) => {
      message.error(error.response.data.error.message);
    },
  });

  const handleDropOtpSubmit = (values) => {
    let status;
    if (customerNoShow) {
      status = "cancelled";
    } else {
      status = "inProgress";
    }

    UpdateBookingStatusMutation.mutate({
      id: bookingId,
      data: {
        status,
        otp: values.dropOtp,
      },
    });
  };

  const handlePickupOtpSubmit = (values) => {
    // console.log("Pickup OTP:", values.pickupOtp);
    UpdateBookingStatusMutation.mutate({
      id: bookingId,
      data: {
        status: "completed",
        otp: values.pickupOtp,
      },
    });
  };

  const handleNoShowChange = (e) => {
    setCustomerNoShow(e.target.checked);
  };

  return (
    <div style={{ margin: "0 auto" }}>
      <Form
        layout="vertical"
        onFinish={
          bookingStatus === "pending"
            ? handleDropOtpSubmit
            : bookingStatus === "inProgress"
            ? handlePickupOtpSubmit
            : null
        }
      >
        {bookingStatus === "completed" || bookingStatus === "cancelled" ? (
          <Descriptions>
            <Descriptions.Item label="Status">
              {bookingStatus}
            </Descriptions.Item>
          </Descriptions>
        ) : (
          <>
            {!customerNoShow && (
              <Form.Item
                label={
                  bookingStatus === "inProgress"
                    ? "Pickup OTP"
                    : bookingStatus === "pending"
                    ? "Drop OTP"
                    : ""
                }
                name={
                  bookingStatus === "inProgress"
                    ? "pickupOtp"
                    : bookingStatus === "pending"
                    ? "dropOtp"
                    : "otp"
                }
                // rules={[
                //   { required: true, message: "Please enter the OTP!" },
                //   { len: 6, message: "OTP must be 6 digits!" },
                // ]}
              >
                <Input maxLength={6} placeholder="Enter OTP" />
              </Form.Item>
            )}
            {(bookingStatus === "pending")  && (
              <Form.Item>
                <Checkbox
                  checked={customerNoShow}
                  onChange={handleNoShowChange}
                >
                  Customer didn’t show up
                </Checkbox>
              </Form.Item>
            )}
            <Form.Item>
              <Button type="primary" htmlType="submit">
                {customerNoShow
                  ? "Submit No Show"
                  : bookingStatus === "inProgress"
                  ? "Submit Pickup OTP"
                  : bookingStatus === "pending"
                  ? "Submit Drop OTP"
                  : ""}
              </Button>
            </Form.Item>
          </>
        )}
      </Form>
    </div>
  );
};

export default OtpForm;
