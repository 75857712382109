import { API_URLS } from "../configs/api.urls";
import { axiosPrivate } from "../configs/axios";

export const CreateStripeSession = async (data) => {
  return await axiosPrivate({
    url: API_URLS.CREATE_STRIPE_SESSION_URL,
    method: "POST",
    data: data,
  });
};

export const GetPaymentStatus = async (sessionId) => {
  return await axiosPrivate({
    url: `${API_URLS.GET_STRIPE_PAYMENT_STATUS_URL}/${sessionId}`,
    method: "GET",
  });
};
