/*
 * Copyright © 2023-2024 www.4bex.com
 *
 * Licensed under the www.4bex.com License, Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.4bex.com/license
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { PlusOutlined } from "@ant-design/icons";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  Button,
  Flex,
  Form,
  Input,
  InputNumber,
  message,
  Modal,
  Select,
  Tag,
  Tooltip,
  Typography,
} from "antd";
import TextArea from "antd/es/input/TextArea";
import { Option } from "antd/es/mentions";
import React, { useState } from "react";
import { updateStorageData } from "../../../common/api/storage";

const predefinedTags = [
  { color: "gold", label: "Highly Rated" },
  { color: "green", label: "Open 24/hr" },
  { color: "blue", label: "Open Late" },
  { color: "green", label: "Budget Friendly" },
  { color: "purple", label: "Social Space" },
  { color: "gold", label: "Premium" },
  { color: "silver", label: "VIP Service" },
  { color: "gold", label: "High-End" },
  { color: "red", label: "Exclusive" },
];

const UpdateStoreModal = ({ storeInfo, setIsModalVisible, isModalVisible }) => {
  // Modal visibility state
  const [form] = Form.useForm(); // Ant Design form hook

  form.setFieldsValue({
    ...storeInfo,
    longitude: storeInfo?.location?.lng || null,
    latitude: storeInfo?.location?.lat || null,
    address1: storeInfo?.address?.address1 || null,
    address2: storeInfo?.address?.address2 || null,
    country: storeInfo?.address?.country || null,
    postalCode: storeInfo?.address?.postalCode || null,
    storeCapacity: storeInfo?.capacity || null,
    dailyRate: storeInfo?.pricing?.dailyRate || null,
    hourlyRate: storeInfo?.pricing?.hourlyRate || null,
  });

  const queryClient = useQueryClient();
  const UpdateStorageMutation = useMutation({
    mutationFn: ({ id, data }) => updateStorageData(id, data),
    onSuccess: (data) => {
      queryClient.invalidateQueries(["storage", "all"], { exact: true });
      message.success(data.data.success.message);
      setIsModalVisible(false);
    },
    onError: (error) => {
      console.log(JSON.stringify(error.response.data.error));
      const fieldsErrors = Object.keys(error.response.data.error).map(
        (field) => ({
          name: field, // Field name in the form
          errors: [error.response.data.error[field]], // Error message as an array
        })
      );

      // Set the errors in the form
      return form.setFields(fieldsErrors);
    },
  });

  // Handle form submission
  const handleSubmit = (values) => {
    UpdateStorageMutation.mutate({
      id: storeInfo.id,
      data: {
        ...values,
        address: {
          address1: values.address1 || "",
          address2: values.address2 || "",
          country: values.country || "",
          postalCode: values.postalCode || "",
        },
        isDaily: true,
        isHourly: true,
        tags,
      },
    });
  };

  const [tags, setTags] = useState(storeInfo?.tags || []);
  const [inputVisible, setInputVisible] = useState(false);

  const handleClose = (removedTag) => {
    const newTags = tags.filter((tag) => tag.label !== removedTag.label);
    setTags(newTags);
  };

  const handleSelectTag = (tag) => {
    if (!tags.some((t) => t.label === tag.label)) {
      setTags([...tags, tag]);
      setInputVisible(false);
    }
  };

  return (
    <>
      <Modal
        title="Update Store Information"
        open={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        footer={null} // Disable default footer
        width={600} // You can customize the modal width
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={handleSubmit} // Trigger form submission
        >
          <Form.Item
            label="Store Name"
            name="title"
            rules={[{ required: true, message: "Please enter the store name" }]}
          >
            <Input placeholder="Enter your store name" />
          </Form.Item>
          <Form.Item
            label="Store Sub Title"
            name="subTitle"
            rules={[
              { required: true, message: "Please enter the store sub title" },
            ]}
          >
            <Input placeholder="Enter your store sub title" />
          </Form.Item>
          <Form.Item name="description" label="Description">
            <TextArea
              rows={4}
              placeholder="Additional details of the located store for direction"
            />
          </Form.Item>
          <Form.Item
            label="Business Registration Number / VAT Number"
            name="registrationNumber"
          >
            <Input placeholder="Enter registration number or VAT number" />
          </Form.Item>
          <Form.Item name="address1" label="Address 1">
            <Input placeholder="Address 1" />
          </Form.Item>
          <Form.Item name="address2" label="Address 2">
            <Input placeholder="Address 2" />
          </Form.Item>
          <Form.Item name="postalCode" label="Postal Code">
            <Input placeholder="Postal Code" />
          </Form.Item>
          {/* Country Selector */}
          <Form.Item name="country" label="Country">
            <Select placeholder="Select a country">
              <Option value="usa">United States</Option>
              <Option value="canada">Canada</Option>
              <Option value="mexico">Mexico</Option>
              {/* Add more countries as needed */}
            </Select>
          </Form.Item>
          <Form.Item name="longitude" label="Longitude">
            <Input placeholder="Longitude" type="number" />
          </Form.Item>
          <Form.Item name="latitude" label="latitude" type="number">
            <Input placeholder="Latitude" />
          </Form.Item>
          <Form.Item>
            <Flex gap="4px 0" wrap>
              {tags?.map((tag, index) => {
                const isLongTag = tag.label.length > 20;
                const tagElem = (
                  <Tag
                    key={tag.label}
                    color={tag.color}
                    closable={index !== 0}
                    style={{
                      userSelect: "none",
                    }}
                    onClose={() => handleClose(tag)}
                  >
                    {isLongTag ? `${tag.label.slice(0, 20)}...` : tag.label}
                  </Tag>
                );
                return isLongTag ? (
                  <Tooltip title={tag.label} key={tag.label}>
                    {tagElem}
                  </Tooltip>
                ) : (
                  tagElem
                );
              })}
              {inputVisible ? (
                <Select
                  style={{ width: 200 }}
                  placeholder="Select Tag"
                  onChange={(value) =>
                    handleSelectTag(
                      predefinedTags.find((tag) => tag.label === value)
                    )
                  }
                  options={predefinedTags
                    .filter((tag) => !tags?.some((t) => t.label === tag.label))
                    .map((tag) => ({ value: tag.label, label: tag.label }))}
                />
              ) : (
                predefinedTags
                  .filter((tag) => !tags?.some((t) => t.label === tag.label))
                  .map((tag) => ({ value: tag.label, label: tag.label }))
                  .length > 0 && (
                  <Tag
                    icon={<PlusOutlined />}
                    onClick={() => setInputVisible(true)}
                  >
                    New Tag
                  </Tag>
                )
              )}
            </Flex>
          </Form.Item>

          <Form.Item
            className="step-store-label"
            layout={"horizontal"}
            label={
              <div className="step-store-name-desc">
                <div>
                  <Typography.Text strong>Storage Capacity</Typography.Text>
                </div>
                <div>
                  <Typography.Text
                    type="secondary"
                    style={{ display: "block", fontSize: 12, paddingBottom:"3px" }}
                  >
                    Storage Space capacity
                  </Typography.Text>
                </div>
              </div>
            }
            name="storeCapacity"
            rules={[
              { required: true, message: "Please enter the store capacity" },
            ]}
          >
            <InputNumber min={1} max={100} defaultValue={1} />
          </Form.Item>

          {/* Daily Rate */}

          <Form.Item
            className="step-store-label"
            layout="horizontal"
            label={
              <div className="step-store-name-desc">
                <div>
                  <Typography.Text strong>Daily Rate</Typography.Text>
                </div>
                <div>
                  <Typography.Text
                    type="secondary"
                    style={{ display: "block", fontSize: 12 }}
                  >
                    Daily rate for one storage space
                  </Typography.Text>
                </div>
              </div>
            }
            name="dailyRate"
            rules={[{ required: true, message: "Please enter the daily rate" }]}
          >
            <InputNumber min={1} defaultValue={1} placeholder="Rate" />
          </Form.Item>

          {/* Hourly Rate */}

          <Form.Item
            className="step-store-label"
            layout="horizontal"
            label={
              <div className="step-store-name-desc">
                <div>
                  <Typography.Text strong>Hourly Rate</Typography.Text>
                </div>
                <div>
                  <Typography.Text
                    type="secondary"
                    style={{ display: "block", fontSize: 12 }}
                  >
                    Hourly rate for one storage space
                  </Typography.Text>
                </div>
              </div>
            }
            name="hourlyRate"
            rules={[
              { required: true, message: "Please enter the hourly rate" },
            ]}
          >
            <InputNumber min={1} defaultValue={1} placeholder="Rate" />
          </Form.Item>
          <Form.Item name="status" label="Status">
            <Select placeholder="Select a Status">
              <Option value="pending">Pending</Option>
              <Option value="approved">Approved</Option>
              <Option value="deactivate">Deactivate</Option>
              {/* Add more countries as needed */}
            </Select>
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" block>
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default UpdateStoreModal;
