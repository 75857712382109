/*
 * Copyright © 2023-2024 www.4bex.com
 *
 * Licensed under the www.4bex.com License, Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.4bex.com/license
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React, {useEffect, useRef} from "react";
import CommentSection from "./StoreComment/CommentSection";
import StoreSection from "./StoreView/StoreSection";

const StoreView = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
  }, []);

  const commentSectionRef = useRef(null); // Create a ref for the Comment Section

  const handleScrollToComments = () => {
    if (commentSectionRef.current) {
      commentSectionRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div>
      <StoreSection onSeeComments={handleScrollToComments} />
      <div ref={commentSectionRef}>
        <CommentSection
          onSeeComments={handleScrollToComments}
          isLoggedIn={true}
        />
      </div>
    </div>
  );
};

export default StoreView;
