import { API_URLS } from "../configs/api.urls";
import { axiosPrivate } from "../configs/axios";

export const getCurrentUser = async () => {
  return await axiosPrivate({
    url: API_URLS.GET_ME_URL,
    method: "GET",
  });
};

export const getAllUsers = async (queryParams) => {
  return await axiosPrivate({
    url: API_URLS.GET_ALL_USERS_URL,
    method: "GET",
    params: queryParams,
  });
};
export const CreateNewManager = async (data) => {
  return await axiosPrivate({
    url: API_URLS.POST_NEW_MANAGER_URL,
    method: "POST",
    data,
  });
};

export const updateCurrentUser = async (data) => {
  return await axiosPrivate({
    url: API_URLS.UPDATE_USER_URL,
    method: "PUT",
    data,
  });
};

export const updateUserStatus = async (data) => {
  return await axiosPrivate({
    url: API_URLS.UPDATE_USER_STATUS_URL,
    method: "PUT",
    data,
  });
};

export const deleteCurrentUser = async (data) => {
  return await axiosPrivate({
    url: API_URLS.DELETE_USER_URL,
    method: "DELETE",
    data,
  });
};

export const updateCurrentUserPassword = async (data) => {
  return await axiosPrivate({
    url: API_URLS.UPDATE_USER_PASSWORD_URL,
    method: "PUT",
    data,
  });
};
