import { API_URLS } from "../configs/api.urls";
import { axiosFile } from "../configs/axios";

export const uploadFile = async (file) => {
  const formData = new FormData();
  formData.append("image", file); // Ensure the backend expects the key "file"

  return await axiosFile({
    url: API_URLS.UPLOAD_FILE_URL, // Replace with your upload API endpoint
    method: "POST",
    data: formData,
  });
};
