/*
 * Copyright © 2023-2025 www.4bex.com
 *
 * Licensed under the www.4bex.com License, Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.4bex.com/license
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React, {useEffect, useRef} from 'react';

const TermsAndConditions = () => {
  const isEffectRun = useRef(false);
  useEffect(() => {
    if (isEffectRun.current) return; // Skip if already run
    isEffectRun.current = true;
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
  }, []);
  return (
    <div>
      <h1>Terms & Conditions</h1>
      <p>
        If your country of residence or establishment is within the European Economic Area (“EEA”), Switzerland, or the United Kingdom, the Terms & Conditions for European Users apply to you.
      </p>
      <p>
        If your country of residence or establishment is outside the EEA, Switzerland, and the United Kingdom, the Terms & Conditions for Non-European Users apply to you.
      </p>

      <h2>Terms & Conditions (European users)</h2>
      <p>Last update: 01. January 2025</p>

      <h3>Acceptance of the user terms and conditions</h3>
      <h4>What is Crypto Luggage?</h4>
      <p>
        Crypto Luggage is the new luggage storing concept, revolutionizing the way we travel, meet and interact. Through the Platform, Crypto Luggage makes it possible for Users to offer their storage services as Storage Service Providers to other Users.
      </p>
      <p>
        Crypto Luggage wishes to give you the best travel experience while using Crypto Luggage’s Platform; However, Crypto Luggage has no control over the conduct of any Storage Service Providers, Travelers, or any other Users and disclaims all liability in this regard, cf. sec. 17 and 18. We recommend all Users to carefully and thoroughly examine and check with their respective Storage Service Providers and other Users all necessary details related to their storage services prior to any booking. Your use of any storage services (“Storage Services”), directly or indirectly, from using Crypto Luggage’s Platform, is entirely at your own risk.
      </p>
      <p>
        Crypto Luggage is not a party to any agreement entered into between Storage Service Providers and Travelers in any circumstances. Crypto Luggage is only the provider of the online platform, which enables Users to interact. The User has the sole and ultimate responsibility regarding compliance with all laws, regulations (including without limitation the applicable tax regulations and luggage storage regulations), or any other duties relating to the Storage Services. Crypto Luggage does not accept any liability for any loss, damage, cost, or expense that you may suffer or incur as a result of or in connection with your participation in any Storage Services conducted by a User or a third party. Nor will Crypto Luggage accept any liability as a result of direct or indirect damage to the luggage or its content. Similarly, Crypto Luggage does not accept any liability due to the nature or legality of the content of the luggage.
      </p>
      <p>
        Although Crypto Luggage provides the digital infrastructure – the Platform – Crypto Luggage does not vouch, endorse, or guarantee the performance to fulfill the contract by the customer or the Storage Service Provider. Any references to a Storage Service Provider being “certified” shall solely mean that Crypto Luggage has verified the identity and that the Storage Service Provider, prima facie, has the storage facility available.
      </p>
      <p>
        As a service to the Traveler, Crypto Luggage has ensured that Storage Services booked and paid through the Intermediary Platform are covered by a third-party insurance company. See more about this in section 11.
      </p>

      <h3>Use of the Platform</h3>
      <p>
        In order to enter and use the services provided by the Platform, you must register and create a User account (“User Account”). The registration is free of charge. Registration can be done by using a personal social network account or by completing an online registration available on the Platform. You will receive a confirmation email when your User Account has been established. A User Account is a personal account, and it is not possible to have more than one active Crypto Luggage User Account. All Users can book Storage Services when their User Account has been activated. The registration is in accordance with the Regulation (EU) 2016/679 (General Data Protection Regulation).
      </p>
      <p>
        When customers search, enter, and accept a booking, they are entering into a contract directly with the Storage Service Provider. Crypto Luggage is not a contractual party to the contractual relationship between the Traveler and the Storage Service Provider.
      </p>
      <p>
        You must provide accurate and complete information when creating an Account on the Platform, and you agree not to misrepresent your identity or your Account information. You agree to keep your Account information up to date and accurate. Otherwise, Crypto Luggage will not be able to provide you with the Platform’s services.
      </p>
      <p>
        You may not assign or transfer your rights or delegate your duties under the Account, including these user terms and conditions, without the prior written consent of Crypto Luggage. You must notify us immediately of any unauthorized use of your Account or any other breach of security. Crypto Luggage is not liable for any loss or damage arising from any failure of yours to comply with these user terms and conditions.
      </p>
      <p>
        If you wish to either change your Account information or password or delete and remove your Account, you may use the Platform’s settings menus. Alternatively, you are always welcome to contact customer service at info@cryptoluggagestorage.com. Your Account will be terminated within a reasonable time following your request, and from that date of termination, you will no longer be able to access your Account.
      </p>
      <p>
        Booking of Storage Services can only be made through the Platform and must be done by the Traveler himself/herself.
      </p>
      <p>
        In order to increase transparency and prevention of abuse, and only to the extent permitted by applicable laws, the Storage Provider may request the Traveler to (i) Picture identification issued by a public authority (e.g., passport, driver’s license, or other official picture identification). (ii) Open the luggage and make the content visible.
      </p>

      <h3>Storage Services</h3>
      <p>
        All standard types of luggage may prima facie be delivered to a Storage Service Provider for Storage Services subject to local law, these terms & conditions, in addition to specific terms set forth by the Storage Service Provider. Storage Service Providers may determine specific luggage restrictions.
      </p>
      <p>
        Travelers are permitted to drop off standard luggage for storage subject to the specific terms pertaining to luggage restrictions set forth by the Storage Service Provider. The following types of luggage are not considered standard:
      </p>
      <ul>
        <li>Jewelry</li>
        <li>Cash</li>
        <li>Weapons</li>
        <li>Valuable documents and artworks</li>
        <li>Life-saving medicine</li>
        <li>Explosive items and substances</li>
        <li>Flammable fluids etc.</li>
        <li>Live animals and humans</li>
        <li>Perishable goods, including food etc.</li>
        <li>Narcotics and euphoric substances</li>
        <li>Items that are illegal to possess according to Danish law.</li>
        <li>Fragile items that are not suitable for storage with other luggage.</li>
        <li>Items with significant sentimental value.</li>
        <li>Other items similar to those in pt. 4.2.1</li>
      </ul>

      <h3>User Restrictions</h3>
      <p>
        There are certain conducts that are strictly prohibited on Crypto Luggage’s Platform. We recommend that you, as a traveler, read the following restrictions carefully. Failure to comply with the provisions set forth herein may result in the termination of your access to the Platform and may also expose you to civil and/or criminal liability.
      </p>
      <p>
        You may not, whether you act alone or on behalf of others; (a) use Crypto Luggage’s Platform for any illegal, unlawful, or unauthorized purposes; (b) allow access to your Account to anyone other than yourself. You accept full responsibility for any unauthorized use of the Platform on your behalf; (c) interfere with or disrupt the operation of Crypto Luggage’s Platform; (d) falsely state or otherwise misrepresent your affiliation with any person or entity, or express or imply that Crypto Luggage endorses you, your Platform, your business, or any statement you make; (e) bypass any measures we may use to Storage Services or restrict access to Crypto Luggage’s Platform; (f) transmit or otherwise make available in connection with the Platform any virus, worm, Trojan Horse, time bomb, web bug, spyware, or any other computer code, file, or program that may or is intended to damage the operation of any hardware, software, or telecommunications equipment, or any other actually or potentially harmful, disruptive, or invasive code or component; and/or (g) infringe and/or violate any of the User terms and conditions.
      </p>

      <h3>Claimant’s default</h3>
      <p>
        If the Traveler fails to collect or receive the luggage in a timely manner, or his circumstances otherwise caused it not to be collected in due time, the Storage Provider shall, at the expense of the Traveler, take care of the item until the delay ceases. If the Storage Provider cannot continue to store the luggage without substantial costs or inconvenience, or if the Traveler does not collect it within a reasonable time after he/she has been requested to do so, the Storage Provider is entitled to sell the luggage at the Traveler’s expense. Before the sale takes place, the Storage Provider shall, to the greatest extent possible, give the Traveler timely notice. If the item is sold at a duly announced and held auction or otherwise, the Traveler cannot object to the price obtained. If a sale cannot take place, or it is evident that the costs associated with a sale could not be covered by the sale price, the Storage Provider is entitled to dispose of the item. If delay caused by the Traveler has incurred expenses for the storage of the luggage or other increased costs, the Storage Provider may claim compensation and, for the security of the latter, withhold the item.
      </p>

      <h3>Deposit</h3>
      <p>
        In connection with booking Storage Services, Crypto Luggage will freeze a deposit in your account equal to the selected number of days of storage. The Storage Provider can offset any due amount in the deposited funds if you do not show up for your booking, cf. more detail in section 10. The storage provider can also offset a due amount in your deposit if you do not collect your luggage within the agreed time, cf. section 10. The storage provider can offset due amounts in the deposit according to the general rules of Danish law on claimant’s default, as described in section 6.
      </p>

      <h3>Booking and billing arrangements</h3>
      <p>
        As a registered User, you are welcome to make an online booking with any Storage Service Provider offering Storage Services via Crypto Luggage’s Platform (“Booking”). A User may pay for the Booking by using the online payment system (described below).
      </p>
      <p>
        Once your Booking is confirmed by the Storage Service Provider, you will automatically receive a booking confirmation to your Account or, when entering a mobile phone number, per SMS (“Booking Confirmation”). The estimated amount shown before any order is made is based on the customer’s filled-in information. The price deducted from any card payment will reflect the actual drop-off and pick-up times. The price for this will be shown on the website. The Booking Amount will be paid through a payment gateway (Stripe, cf. below).
      </p>

      <h3>Fees and Payments</h3>
      <p>
        Payments will be processed via our payment service provider, Stripe Payments UK Limited (“Stripe”). Stripe’s payment system enables you to send payments securely online using a Visa card, MasterCard, or American Express. Please note that Crypto Luggage does not control and is not affiliated with Stripe. Stripe is an independent contractor and has no employment or agency relationship with Crypto Luggage. Crypto Luggage is not responsible in any way for the actions or performance (or lack thereof) of Stripe. The use of Stripe’s payment system is at your own risk. It is your responsibility to abide by all the terms specified by Stripe in their terms of use and privacy policies. You acknowledge that you are fully assuming the risks of conducting any transactions via Stripe’s payment system.
      </p>
      <p>
        Crypto Luggage will post prices, including all applicable taxes for Storage Services offered on Crypto Luggage’s Platform. As such, it may be subject to taxation, as required or ordered by the applicable tax law in the country where respective Storage Service Providers reside. Storage Service Providers are solely responsible for complying with the applicable tax law and paying any taxes, including VAT, surcharges, sales taxes, goods and services taxes, and other similar municipal, state, and federal indirect or other withholding and personal or corporate income taxes imposed and levied in connection with Bookings made of their respective Storage Services via Crypto Luggage’s Platform. Storage Service Providers represent and warrant that they will pay such taxes in time and in compliance with the applicable tax law. Failing to pay such taxes as mentioned above or failing to comply with any provisions specified in the applicable tax law may prevent Storage Service Providers from continuing to offer their Storage Services on Crypto Luggage’s Platform, and Crypto Luggage reserves the right to terminate the Storage Service Provider’s Account.
      </p>
      <p>
        Users represent and warrant that they will pay all fees and taxes incurred with the Booking in a timely manner. Failing to settle your payments in connection with a Booking of the Storage Services may prevent you from executing your Booking with Crypto Luggage’s Platform. Crypto Luggage reserves the right to terminate your Account if you do not comply with the payment conditions.
      </p>
      <p>
        Payment information is not stored by Crypto Luggage and cannot be read by Crypto Luggage or the Storage Provider. Payment information used when ordering cannot be changed while the luggage is stored. If the customer wishes to change the payment details, the customer needs to use any new payment details for any future payments from the Platform.
      </p>
      <p>
        The customer receives a receipt for each transaction made. Payment details are also found on the Platform.
      </p>
      <p>
        The estimated amount shown when making a booking of Storage Services is based on the customer’s filled-in information. The price deducted from any card payment will reflect the actual drop-off time and actual pick-up time.
      </p>

      {/*<h3>Cancellation of booking</h3>*/}
      {/*<p>*/}
      {/*  Travelers can cancel a confirmed booking pursuant to the Storage Services’ cancellation policy set by Crypto Luggage. We always recommend you to carefully read the information of the Storage Services’ applicable terms and conditions before you book the Storage Services.*/}
      {/*</p>*/}
      {/*<p>*/}
      {/*  Crypto Luggage shall not bear any liability for any costs, expenses, loss, or damage that you may suffer or incur as a result of a cancellation of a Booking or Storage Service.*/}
      {/*</p>*/}
      {/*<p>*/}
      {/*  The full cancellation policy can be read here: <a href="https://cryptoluggagestorage.com/terms-conditions/cancellation-policy/">https://cryptoluggagestorage.com/terms-conditions/cancellation-policy/</a>.*/}
      {/*</p>*/}

      {/*<h3>Insurance</h3>*/}
      {/*<p>*/}
      {/*  Luggage storage services booked and paid for through the Crypto Luggage platform may be insured by a third-party insurance company, First Marine ApS, up to a maximum of $3000 per bag if the customer elects to purchase insurance. If the customer does not purchase insurance, there is still a guarantee of $500. Payment for the storage service must be made through the Crypto Luggage platform, not in cash or directly to the shop unless specified by the app, in order to be eligible to file an insurance claim.*/}
      {/*</p>*/}
      {/*<p>*/}
      {/*  Upon collecting luggage from the Storage Service Provider, the Traveler is entitled to inspect the luggage and its contents for any visible signs of unauthorized access or damage during storage. If the customer finds any evidence of unauthorized access, damage, or content removal, they must immediately make a complaint to the shop and inform Crypto Luggage while being at the storage location. This will ensure that the incident can be properly linked to the use of the storage service.*/}
      {/*</p>*/}
      {/*<p>*/}
      {/*  To file an insurance claim, the customer must notify Crypto Luggage at info@cryptoluggagestorage.com. Crypto Luggage will then communicate the insurance claim to the Insurance Provider. Crypto Luggage is not an insurance provider and does not guarantee insurance coverage. Any discrepancies with the Insurance Provider must be resolved directly with the Insurance Provider.*/}
      {/*</p>*/}
      {/*<p>*/}
      {/*  Please note that the insurance is only available to customers who purchase it and make their payments through the online service. The insurance does not cover storage services not booked and paid for through the Crypto Luggage platform or where the timer in the Crypto Luggage app is not activated.*/}
      {/*</p>*/}

      <h3>Privacy Policy</h3>
      <p>
        Crypto Luggage respects your privacy and is committed to protecting the information you share with us. Our policy and the type of information we collect are described in our Privacy Policy at <a href="https://cryptoluggagestorage.com/da/terms-conditions/privacy-policy/">https://cryptoluggagestorage.com/da/terms-conditions/privacy-policy/</a>. In order to access and use the Platform, you must first read and agree to Crypto Luggage’s Privacy Policy.
      </p>

      <h3>Intellectual Property Rights</h3>
      <p>
        The Platform and the content of the Platform and any and all intellectual property rights pertaining thereto, including, but not limited to, inventions, trademarks, trade names, service marks, copyrightable materials, and patents, whether or not registered or capable of being registered (collectively “Intellectual Property”) are owned by and/or licensed to Crypto Luggage.
      </p>
      <p>
        To the extent you may choose to provide any feedback, comments, or suggestions (“Feedback”) to Crypto Luggage, Crypto Luggage reserves an exclusive, royalty-free, and irrevocable right to incorporate the Feedback into any of Crypto Luggage’s current or future products, technologies, or services without your prior approval. All Feedback shall be deemed to be non-confidential. Crypto Luggage also reserves the right to use feedback as marketing/promotional material without your prior approval.
      </p>

      <h3>Trademarks and Trade names</h3>
      <p>
        “Crypto Luggage” and any and all marks and logos and all other proprietary identifiers used by Crypto Luggage in connection with the Platform are all trademarks and/or trade names of Crypto Luggage, whether or not registered. All other trademarks, service marks, trade names, and logos that may appear on the Platform belong to their respective owners. No right, license, or interest to Crypto Luggage’s trademarks and/or to any third-party marks is granted hereunder, and therefore you will avoid using any of those marks.
      </p>

      <h3>Linking to Crypto Luggage’s Platform and links to third-party platforms</h3>
      <p>
        We welcome links to any page on our Platform. You are free to establish a hypertext link to the Platform as long as the link does not state or imply any connection or approval of your platform, products, and/or services by Crypto Luggage. You may not link to our Platform from a platform that you do not own or have permission to use.
      </p>
      <p>
        Certain links provided on our Platform permit our users to leave Crypto Luggage’s Platform and enter non-Crypto Luggage platforms or services. Those linked platforms and services are provided solely as a convenience to you. These linked Platforms and services are not under the control of Crypto Luggage, and Crypto Luggage is not responsible for the availability of such external Platforms or services and does not endorse and is not responsible or liable for any content, advertising, products, or other information on or available from such linked Platforms and services or any link contained in linked Platforms or service. Your access to, use of, and reliance upon any such platforms, services, and content and your dealings with such third parties are at your sole risk and expense. Crypto Luggage reserves the right to terminate any link at any time. You further acknowledge and agree that Crypto Luggage shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused, by or in connection with the use of or reliance on any services, content, products, or other materials available on or through such linked platforms or resource.
      </p>

      <h3>Availability</h3>
      <p>
        The platform’s availability and functionality depend on various factors, such as communication networks, software, hardware, and Crypto Luggage’s service providers and contractors. Crypto Luggage does not warrant or guarantee that the Platform will always operate without disruption or interruption or that it will be immune from unauthorized access or error-free.
      </p>

      <h3>Changes to the Platform</h3>
      <p>
        Crypto Luggage reserves the right to modify, amend, correct, improve, make any other changes to, or discontinue, temporarily or permanently, this Platform without notice at any time. You agree and accept that Crypto Luggage shall not be liable to you or to any third party for any modification, suspension, or discontinuance of this Platform or the content included therein.
      </p>

      {/*<h3>Refund</h3>*/}
      {/*<p>*/}
      {/*  In the event that the Storage Services are misleading representation or misconduct on the Platform and in connection with Storage Services through the use of Crypto Luggage’s Platform, you are not entitled to a refund from Crypto Luggage. You agree and accept that the use of Crypto Luggage’s Platform and participation in any Storage Services is solely at your own risk.*/}
      {/*</p>*/}
      {/*<p>*/}
      {/*  You can read our full refund policy here: <a href="https://cryptoluggagestorage.com/terms-conditions/refund-policy/">https://cryptoluggagestorage.com/terms-conditions/refund-policy/</a>.*/}
      {/*</p>*/}

      <h3>Disclaimer</h3>
      <p>
        Crypto Luggage is solely a provider of the digital marketplace that is offered via the Platform. Crypto Luggage does not offer, guarantee, influence, or manage the storage services offered by the Storage Service Provider. Only Storage Service Provider can be liable for matters related to the storage services offered via the platform, cf. sections 17 and 18.
      </p>
      <p>
        Crypto Luggage will neither be liable for any direct nor indirect damage to the luggage or its contents. Furthermore, Crypto Luggage accepts no liability as a result of the nature or legality of the contents of the luggage. It is the responsibility of the Storage Provider to ensure that the Storage Services are performed in accordance with all applicable laws, rules, and regulations.
      </p>
      <p>
        Crypto Luggage is not responsible for personal injury or property damage, or financial loss incurred by the Traveler or any other person, including property and personal injury to the Storage Provider as a result of or in connection with the use of the Platform, cf. sections 17 and 18.
      </p>
      <p>
        Crypto Luggage only communicates with the Traveler on behalf of the Storage Provider and is, therefore, not your contracting party but only the intermediary. It is the Storage Provider who is your contracting party and who provides the storage of your luggage. The storage provider is responsible for the performance of the agreement. This means that all claims and complaints regarding your luggage storage must be directed to the Storage Provider, for example, as a result of delays, cancellations, bankruptcy, theft, and damage to the luggage, as well as compliance with national laws, public regulations (including, without limitation to the applicable tax rules and baggage storage rules) or other rules related to the Storage Services, etc.
      </p>
      <p>
        Thus, Crypto Luggage does not warrant that the Platform is or will be secure, timely, accurate, complete, uninterrupted, without errors, or free of viruses, defects, or other harmful components or other program limitations. Crypto Luggage hereby disclaims all warranties and conditions regarding the use of the Platform, including but not limited to the availability, reliability, or quality of the Platform. Crypto Luggage is not responsible for any error, fault, or mistake related to any content and information displayed within the Platform.
      </p>
      <p>
        Crypto Luggage is not and shall not be responsible for your use of any Storage Services distributed through the Platform, including the nature and quality of the service given by the Storage Provider.
      </p>
      <p>
        Crypto Luggage is not a party to any agreement entered into between Storage Service Providers and Travelers, nor is Crypto Luggage a luggage storage provider or insurer under any circumstances, and is not and shall not be responsible for any error, fault, or mistake of any and all content and information received through the Platform, including by Users, Storage Service Provider or third parties. Crypto Luggage is not acting as an agent in any capacity for any Member except as specified in the Payments Terms. Please note that Crypto Luggage does not endorse any entity, product, or service mentioned in any User Generated Content available on the Platform. Crypto Luggage makes no warranty or assurance to you with respect to the existence, scope, and compliance as required by any applicable law with respect to such Storage Services. Crypto Luggage is not responsible and has no liability for any product or service provided by any person or entity other than Crypto Luggage. Crypto Luggage bears no responsibility for any engagement with third-party service providers or any service and/or content supplied by a third party.
      </p>
      <p>
        Storage Service Providers are independent contractors and are not to be considered an employee, agent, partner, or joint venture of Crypto Luggage. Storage Service Providers shall not bind nor attempt to bind Crypto Luggage to any contract.
      </p>
      <p>
        You agree and accept that the use of the Platform and the services provided is entirely at your own risk.
      </p>

      <h3>Limitation of Liability</h3>
      <p>
        The use of Crypto Luggage’s Platform and participation in any Storage Services is solely at your own risk. To the maximum extent legally permissible, Crypto Luggage is not liable for any damages, hereunder but not limited to, direct, indirect, punitive, incidental, or consequential damages of any kind (including without limitation loss of goodwill, profits, or data) resulting from or arising out of or relating to the Platform, Crypto Luggage services, and/or your participation in any Storage Services.
      </p>

      <h3>Indemnification</h3>
      <p>
        You agree to defend, indemnify, and hold harmless Crypto Luggage from and against any and all claims, damages, obligations, losses, liabilities, costs, debts, and expenses arising from (i) your use of Crypto Luggage’s Platform; (ii) your violation of any term of these User terms and conditions; (iii) your violation of any third-party rights, including without limitation any intellectual property rights or privacy right of such third party in connection with your use of the Platform; and/or (iv) any damage of any sort, whether direct, indirect, special, or consequential, you may cause to any third party with relation to your use of the Platform.
      </p>

      <h3>Amendments to the User terms and conditions</h3>
      <p>
        Crypto Luggage reserves the right to change the User terms and conditions from time to time, so please re-visit this page frequently. In case of any material change, we will make reasonable efforts to post a clear notice on the Platform and/or will send you an email (to the extent that you provided us with such email address) regarding such change. In the event that the User terms and conditions must be amended to comply with any legal requirements, the amendments may take effect immediately or as required by the law and without any prior notice.
      </p>

      <h3>Termination</h3>
      <p>
        Crypto Luggage reserves the right to block your access to the Platform and/or temporarily or permanently limit your access to the Platform, at its sole discretion, in addition to any other remedies that may be available to Crypto Luggage under any applicable law. Such actions by Crypto Luggage may be taken, for example, if Crypto Luggage deems that you have breached the User terms and conditions in any manner. Additionally, Crypto Luggage may at any time, at its sole discretion, cease the operation of the Platform or any part thereof, temporarily or permanently, without giving any prior notice. You agree and acknowledge that Crypto Luggage does not assume any responsibility with respect to or in connection with the termination of the Platform’s operation and loss of any data. The provisions of these User terms and conditions survive the termination of these User terms and conditions.
      </p>

      <h3>Advertisements</h3>
      <p>
        Crypto Luggage may accept advertisements from third parties. Please be aware that Crypto Luggage makes no warranties or representations concerning such advertisements, whether Crypto Luggage has control over such advertisements. Any and all information contained in such advertisements belongs solely to the advertisers. Crypto Luggage will not be liable for any damage or loss incurred to you as a result of or in connection with such advertisements.
      </p>

      <h3>Law and jurisdiction</h3>
      <p>
        The User terms and conditions are governed by and shall be construed in accordance with Danish law. Any dispute arising from or related to your use of Crypto Luggage’s Platform and/or services shall be settled by the City Court of Copenhagen (in Danish: Københavns Byret) in accordance with Danish law.
      </p>
      <p>
        Should any provision of these User terms and conditions be held by a court of competent jurisdiction to be illegal, invalid, or unenforceable, such provision may be modified by such court in compliance with the law giving effect to the intent of the parties and enforced as modified. All other terms and conditions of these User terms and conditions shall remain in full force and effect and be construed in accordance with the modified provision.
      </p>
      <p>
        Crypto Luggage reserves the right to assign or transfer the rights under these User terms and conditions without restriction or notification.
      </p>

      <h3>For information, questions, or notification of errors, please contact:</h3>
      <p>
        Suppose you have any questions regarding the User terms and conditions. In that case, you are always welcome to contact customer service at info@cryptoluggagestorage.com, and we will make an effort to reply within a reasonable time.
      </p>

      <h3>Information on complaints</h3>
      <p>
        Travelers who are not satisfied with Crypto Luggage’s services are always welcome to contact customer service at info@cryptoluggagestorage.com. If the problem cannot be solved satisfactorily for the Traveler, he/she can lodge a complaint to:
      </p>
      <p>
        The Competition and Consumer Agency’s Center for Complaints<br />
        Carl Jacobsens Road 35<br />
        2500 Valby<br />
        <a href="https://www.forbrug.dk">www.forbrug.dk</a>
      </p>
      <p>
        The complaint can be filed online here: <a href="https://minsag.forbrug.dk/FKVWeb/CheckComplaintStart.aspx">https://minsag.forbrug.dk/FKVWeb/CheckComplaintStart.aspx</a>
      </p>
      <p>
        If you are a consumer domiciled in another EU country, you can state your complaint in the EU Commission’s online complaint platform: <a href="http://ec.europa.eu/consumers/odr/">http://ec.europa.eu/consumers/odr/</a>
      </p>

      <h2>Terms & Conditions (non-European users)</h2>
      <p>Last update: 11. January 2025</p>

      <h3>Acceptance of the user terms and conditions</h3>
      <h4>What is Crypto Luggage?</h4>
      <p>
        Crypto Luggage is the new luggage storing concept, revolutionizing the way we travel, meet and interact. Through the Platform, Crypto Luggage makes it possible for Users to offer their storage services as Storage Service Providers to other Users.
      </p>
      <p>
        Crypto Luggage wishes to give you the best travel experience while using Crypto Luggage’s Platform; However, Crypto Luggage has no control over the conduct of any Storage Service Providers, Travelers, or any other Users and disclaims all liability in this regard, cf. sec. 17 and 18. We recommend all Users to carefully and thoroughly examine and check with their respective Storage Service Providers and other Users all necessary details related to their storage services prior to any booking. Your use of any storage services (“Storage Services”), directly or indirectly, from using Crypto Luggage’s Platform, is entirely at your own risk.
      </p>
      <p>
        Crypto Luggage is not a party to any agreement entered into between Storage Service Providers and Travelers in any circumstances. Crypto Luggage is only the provider of the online platform, which enables Users to interact. The User has the sole and ultimate responsibility regarding compliance with all laws, regulations (including without limitation the applicable tax regulations and luggage storage regulations), or any other duties relating to the Storage Services. Crypto Luggage does not accept any liability for any loss, damage, cost, or expense that you may suffer or incur as a result of or in connection with your participation in any Storage Services conducted by a User or a third party. Nor will Crypto Luggage accept any liability as a result of direct or indirect damage to the luggage or its content. Similarly, Crypto Luggage does not accept any liability due to the nature or legality of the content of the luggage.
      </p>
      <p>
        Although Crypto Luggage provides the digital infrastructure – the Platform – Crypto Luggage does not vouch, endorse, or guarantee the performance to fulfill the contract by the customer or the Storage Service Provider. Any references to a Storage Service Provider being “certified” shall solely mean that Crypto Luggage has verified the identity and that the Storage Service Provider, prima facie, has the storage facility available.
      </p>
      <p>
        As a service to the Traveler, Crypto Luggage has ensured that Storage Services booked and paid through the Intermediary Platform are covered by a third-party insurance company. See more about this in section 11.
      </p>

      <h3>Use of the Platform</h3>
      <p>
        In order to enter and use the services provided by the Platform, you must register and create a User account (“User Account”). The registration is free of charge. Registration can be done by using a personal social network account or by completing an online registration available on the Platform. You will receive a confirmation email when your User Account has been established. A User Account is a personal account, and it is not possible to have more than one active Crypto Luggage User Account. All Users can book Storage Services when their User Account has been activated. The registration is in accordance with the Regulation (EU) 2016/679 (General Data Protection Regulation).
      </p>
      <p>
        When customers search, enter, and accept a booking, they are entering into a contract directly with the Storage Service Provider. Crypto Luggage is not a contractual party to the contractual relationship between the Traveler and the Storage Service Provider.
      </p>
      <p>
        You must provide accurate and complete information when creating an Account on the Platform, and you agree not to misrepresent your identity or your Account information. You agree to keep your Account information up to date and accurate. Otherwise, Crypto Luggage will not be able to provide you with the Platform’s services.
      </p>
      <p>
        You may not assign or transfer your rights or delegate your duties under the Account, including these user terms and conditions, without the prior written consent of Crypto Luggage. You must notify us immediately of any unauthorized use of your Account or any other breach of security. Crypto Luggage is not liable for any loss or damage arising from any failure of yours to comply with these user terms and conditions.
      </p>
      <p>
        If you wish to either change your Account information or password or delete and remove your Account, you may use the Platform’s settings menus. Alternatively, you are always welcome to contact customer service at info@cryptoluggagestorage.com. Your Account will be terminated within a reasonable time following your request, and from that date of termination, you will no longer be able to access your Account.
      </p>
      <p>
        Booking of Storage Services can only be made through the Platform and must be done by the Traveler himself/herself.
      </p>
      <p>
        In order to increase transparency and prevention of abuse, and only to the extent permitted by applicable laws, the Storage Provider may request the Traveler to (i) Picture identification issued by a public authority (e.g., passport, driver’s license, or other official picture identification). (ii) Open the luggage and make the content visible.
      </p>

      <h3>Storage Services</h3>
      <p>
        All standard types of luggage may prima facie be delivered to a Storage Service Provider for Storage Services subject to local law, these terms & conditions, in addition to specific terms set forth by the Storage Service Provider. Storage Service Providers may determine specific luggage restrictions.
      </p>
      <p>
        Travelers are permitted to drop off standard luggage for storage subject to the specific terms pertaining to luggage restrictions set forth by the Storage Service Provider. The following types of luggage are not considered standard:
      </p>
      <ul>
        <li>Jewelry</li>
        <li>Cash</li>
        <li>Weapons</li>
        <li>Valuable documents and artworks</li>
        <li>Life-saving medicine</li>
        <li>Explosive items and substances</li>
        <li>Flammable fluids etc.</li>
        <li>Live animals and humans</li>
        <li>Perishable goods, including food etc.</li>
        <li>Narcotics and euphoric substances</li>
        <li>Items that are illegal to possess according to Danish law.</li>
        <li>Fragile items that are not suitable for storage with other luggage.</li>
        <li>Items with significant sentimental value.</li>
        <li>Other items similar to those in pt. 4.2.1</li>
      </ul>

      <h3>User Restrictions</h3>
      <p>
        There are certain conducts that are strictly prohibited on Crypto Luggage’s Platform. We recommend that you, as a traveler, read the following restrictions carefully. Failure to comply with the provisions set forth herein may result in the termination of your access to the Platform and may also expose you to civil and/or criminal liability.
      </p>
      <p>
        You may not, whether you act alone or on behalf of others; (a) use Crypto Luggage’s Platform for any illegal, unlawful, or unauthorized purposes; (b) allow access to your Account to anyone other than yourself. You accept full responsibility for any unauthorized use of the Platform on your behalf; (c) interfere with or disrupt the operation of Crypto Luggage’s Platform; (d) falsely state or otherwise misrepresent your affiliation with any person or entity, or express or imply that Crypto Luggage endorses you, your Platform, your business, or any statement you make; (e) bypass any measures we may use to Storage Services or restrict access to Crypto Luggage’s Platform; (f) transmit or otherwise make available in connection with the Platform any virus, worm, Trojan Horse, time bomb, web bug, spyware, or any other computer code, file, or program that may or is intended to damage the operation of any hardware, software, or telecommunications equipment, or any other actually or potentially harmful, disruptive, or invasive code or component; and/or (g) infringe and/or violate any of the User terms and conditions.
      </p>

      <h3>Claimant’s default</h3>
      <p>
        If the Traveler fails to collect or receive the luggage in a timely manner, or his circumstances otherwise caused it not to be collected in due time, the Storage Provider shall, at the expense of the Traveler, take care of the item until the delay ceases. If the Storage Provider cannot continue to store the luggage without substantial costs or inconvenience, or if the Traveler does not collect it within a reasonable time after he/she has been requested to do so, the Storage Provider is entitled to sell the luggage at the Traveler’s expense. Before the sale takes place, the Storage Provider shall, to the greatest extent possible, give the Traveler timely notice. If the item is sold at a duly announced and held auction or otherwise, the Traveler cannot object to the price obtained. If a sale cannot take place, or it is evident that the costs associated with a sale could not be covered by the sale price, the Storage Provider is entitled to dispose of the item. If delay caused by the Traveler has incurred expenses for the storage of the luggage or other increased costs, the Storage Provider may claim compensation and, for the security of the latter, withhold the item.
      </p>

      <h3>Deposit</h3>
      <p>
        In connection with booking Storage Services, Crypto Luggage will freeze a deposit in your account equal to the selected number of days of storage. The Storage Provider can offset any due amount in the deposited funds if you do not show up for your booking, cf. more detail in section 10. The storage provider can also offset a due amount in your deposit if you do not collect your luggage within the agreed time, cf. section 10. The storage provider can offset due amounts in the deposit according to the general rules of Danish law on claimant’s default, as described in section 6.
      </p>

      <h3>Booking and billing arrangements</h3>
      <p>
        As a registered User, you are welcome to make an online booking with any Storage Service Provider offering Storage Services via Crypto Luggage’s Platform (“Booking”). A User may pay for the Booking by using the online payment system (described below).
      </p>
      <p>
        Once your Booking is confirmed by the Storage Service Provider, you will automatically receive a booking confirmation to your Account or, when entering a mobile phone number, per SMS (“Booking Confirmation”). The estimated amount shown before any order is made is based on the customer’s filled-in information. The price deducted from any card payment will reflect the actual drop-off and pick-up times. The price for this will be shown on the website. The Booking Amount will be paid through a payment gateway (Stripe, cf. below).
      </p>

      <h3>Fees and Payments</h3>
      <p>
        Payments will be processed via our payment service provider, Stripe Payments UK Limited (“Stripe”). Stripe’s payment system enables you to send payments securely online using a Visa card, MasterCard, or American Express. Please note that Crypto Luggage does not control and is not affiliated with Stripe. Stripe is an independent contractor and has no employment or agency relationship with Crypto Luggage. Crypto Luggage is not responsible in any way for the actions or performance (or lack thereof) of Stripe. The use of Stripe’s payment system is at your own risk. It is your responsibility to abide by all the terms specified by Stripe in their terms of use and privacy policies. You acknowledge that you are fully assuming the risks of conducting any transactions via Stripe’s payment system.
      </p>
      <p>
        Crypto Luggage will post prices, including all applicable taxes for Storage Services offered on Crypto Luggage’s Platform. As such, it may be subject to taxation, as required or ordered by the applicable tax law in the country where respective Storage Service Providers reside. Storage Service Providers are solely responsible for complying with the applicable tax law and paying any taxes, including VAT, surcharges, sales taxes, goods and services taxes, and other similar municipal, state, and federal indirect or other withholding and personal or corporate income taxes imposed and levied in connection with Bookings made of their respective Storage Services via Crypto Luggage’s Platform. Storage Service Providers represent and warrant that they will pay such taxes in time and in compliance with the applicable tax law. Failing to pay such taxes as mentioned above or failing to comply with any provisions specified in the applicable tax law may prevent Storage Service Providers from continuing to offer their Storage Services on Crypto Luggage’s Platform, and Crypto Luggage reserves the right to terminate the Storage Service Provider’s Account.
      </p>
      <p>
        Users represent and warrant that they will pay all fees and taxes incurred with the Booking in a timely manner. Failing to settle your payments in connection with a Booking of the Storage Services may prevent you from executing your Booking with Crypto Luggage’s Platform. Crypto Luggage reserves the right to terminate your Account if you do not comply with the payment conditions.
      </p>
      <p>
        Payment information is not stored by Crypto Luggage and cannot be read by Crypto Luggage or the Storage Provider. Payment information used when ordering cannot be changed while the luggage is stored. If the customer wishes to change the payment details, the customer needs to use any new payment details for any future payments from the Platform.
      </p>
      <p>
        The customer receives a receipt for each transaction made. Payment details are also found on the Platform.
      </p>
      <p>
        The estimated amount shown when making a booking of Storage Services is based on the customer’s filled-in information. The price deducted from any card payment will reflect the actual drop-off time and actual pick-up time.
      </p>

      {/*<h3>Cancellation of booking</h3>*/}
      {/*<p>*/}
      {/*  Travelers can cancel a confirmed booking pursuant to the Storage Services’ cancellation policy set by Crypto Luggage. We always recommend you to carefully read the information of the Storage Services’ applicable terms and conditions before you book the Storage Services.*/}
      {/*</p>*/}
      {/*<p>*/}
      {/*  Crypto Luggage shall not bear any liability for any costs, expenses, loss, or damage that you may suffer or incur as a result of a cancellation of a Booking or Storage Service.*/}
      {/*</p>*/}
      {/*<p>*/}
      {/*  The full cancellation policy can be read here: <a href="https://cryptoluggagestorage.com/terms-conditions/cancellation-policy/">https://cryptoluggagestorage.com/terms-conditions/cancellation-policy/</a>.*/}
      {/*</p>*/}

      <h3>Insurance</h3>
      <p>
        Luggage storage services booked and paid for through the Crypto Luggage platform may be insured by a third-party insurance company, First Marine ApS, up to a maximum of $3000 per bag if the customer elects to purchase insurance. If the customer does not purchase insurance, there is still a guarantee of $500. Payment for the storage service must be made through the Crypto Luggage platform, not in cash or directly to the shop unless specified by the app, in order to be eligible to file an insurance claim.
      </p>
      <p>
        Upon collecting luggage from the Storage Service Provider, the Traveler is entitled to inspect the luggage and its contents for any visible signs of unauthorized access or damage during storage. If the customer finds any evidence of unauthorized access, damage, or content removal, they must immediately make a complaint to the shop and inform Crypto Luggage while being at the storage location. This will ensure that the incident can be properly linked to the use of the storage service.
      </p>
      <p>
        To file an insurance claim, the customer must notify Crypto Luggage at info@cryptoluggagestorage.com. Crypto Luggage will then communicate the insurance claim to the Insurance Provider. Crypto Luggage is not an insurance provider and does not guarantee insurance coverage. Any discrepancies with the Insurance Provider must be resolved directly with the Insurance Provider.
      </p>
      <p>
        Please note that the insurance is only available to customers who purchase it and make their payments through the online service. The insurance does not cover storage services not booked and paid for through the Crypto Luggage platform or where the timer in the Crypto Luggage app is not activated.
      </p>

      <h3>Privacy Policy</h3>
      <p>
        Crypto Luggage respects your privacy and is committed to protecting the information you share with us. Our policy and the type of information we collect are described in our Privacy Policy at <a href="https://cryptoluggagestorage.com/da/terms-conditions/privacy-policy/">https://cryptoluggagestorage.com/da/terms-conditions/privacy-policy/</a>. In order to access and use the Platform, you must first read and agree to Crypto Luggage’s Privacy Policy.
      </p>

      <h3>Intellectual Property Rights</h3>
      <p>
        The Platform and the content of the Platform and any and all intellectual property rights pertaining thereto, including, but not limited to, inventions, trademarks, trade names, service marks, copyrightable materials, and patents, whether or not registered or capable of being registered (collectively “Intellectual Property”) are owned by and/or licensed to Crypto Luggage.
      </p>
      <p>
        To the extent you may choose to provide any feedback, comments, or suggestions (“Feedback”) to Crypto Luggage, Crypto Luggage reserves an exclusive, royalty-free, and irrevocable right to incorporate the Feedback into any of Crypto Luggage’s current or future products, technologies, or services without your prior approval. All Feedback shall be deemed to be non-confidential. Crypto Luggage also reserves the right to use feedback as marketing/promotional material without your prior approval.
      </p>

      <h3>Trademarks and Trade names</h3>
      <p>
        “Crypto Luggage” and any and all marks and logos and all other proprietary identifiers used by Crypto Luggage in connection with the Platform are all trademarks and/or trade names of Crypto Luggage, whether or not registered. All other trademarks, service marks, trade names, and logos that may appear on the Platform belong to their respective owners. No right, license, or interest to Crypto Luggage’s trademarks and/or to any third-party marks is granted hereunder, and therefore you will avoid using any of those marks.
      </p>

      <h3>Linking to Crypto Luggage’s Platform and links to third-party platforms</h3>
      <p>
        We welcome links to any page on our Platform. You are free to establish a hypertext link to the Platform as long as the link does not state or imply any connection or approval of your platform, products, and/or services by Crypto Luggage. You may not link to our Platform from a platform that you do not own or have permission to use.
      </p>
      <p>
        Certain links provided on our Platform permit our users to leave Crypto Luggage’s Platform and enter non-Crypto Luggage platforms or services. Those linked platforms and services are provided solely as a convenience to you. These linked Platforms and services are not under the control of Crypto Luggage, and Crypto Luggage is not responsible for the availability of such external Platforms or services and does not endorse and is not responsible or liable for any content, advertising, products, or other information on or available from such linked Platforms and services or any link contained in linked Platforms or service. Your access to, use of, and reliance upon any such platforms, services, and content and your dealings with such third parties are at your sole risk and expense. Crypto Luggage reserves the right to terminate any link at any time. You further acknowledge and agree that Crypto Luggage shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused, by or in connection with the use of or reliance on any services, content, products, or other materials available on or through such linked platforms or resource.
      </p>

      <h3>Availability</h3>
      <p>
        The platform’s availability and functionality depend on various factors, such as communication networks, software, hardware, and Crypto Luggage’s service providers and contractors. Crypto Luggage does not warrant or guarantee that the Platform will always operate without disruption or interruption or that it will be immune from unauthorized access or error-free.
      </p>

      <h3>Changes to the Platform</h3>
      <p>
        Crypto Luggage reserves the right to modify, amend, correct, improve, make any other changes to, or discontinue, temporarily or permanently, this Platform without notice at any time. You agree and accept that Crypto Luggage shall not be liable to you or to any third party for any modification, suspension, or discontinuance of this Platform or the content included therein.
      </p>

      {/*<h3>Refund</h3>*/}
      {/*<p>*/}
      {/*  In the event that the Storage Services are misleading representation or misconduct on the Platform and in connection with Storage Services through the use of Crypto Luggage’s Platform, you are not entitled to a refund from Crypto Luggage. You agree and accept that the use of Crypto Luggage’s Platform and participation in any Storage Services is solely at your own risk.*/}
      {/*</p>*/}
      {/*<p>*/}
      {/*  You can read our full refund policy here: <a href="https://cryptoluggagestorage.com/terms-conditions/refund-policy/">https://cryptoluggagestorage.com/terms-conditions/refund-policy/</a>.*/}
      {/*</p>*/}

      <h3>Disclaimer</h3>
      <p>
        Crypto Luggage is solely a provider of the digital marketplace that is offered via the Platform. Crypto Luggage does not offer, guarantee, influence, or manage the storage services offered by the Storage Service Provider. Only Storage Service Provider can be liable for matters related to the storage services offered via the platform, cf. sections 17 and 18.
      </p>
      <p>
        Crypto Luggage will neither be liable for any direct nor indirect damage to the luggage or its contents. Furthermore, Crypto Luggage accepts no liability as a result of the nature or legality of the contents of the luggage. It is the responsibility of the Storage Provider to ensure that the Storage Services are performed in accordance with all applicable laws, rules, and regulations.
      </p>
      <p>
        Crypto Luggage is not responsible for personal injury or property damage, or financial loss incurred by the Traveler or any other person, including property and personal injury to the Storage Provider as a result of or in connection with the use of the Platform, cf. sections 17 and 18.
      </p>
      <p>
        Crypto Luggage only communicates with the Traveler on behalf of the Storage Provider and is, therefore, not your contracting party but only the intermediary. It is the Storage Provider who is your contracting party and who provides the storage of your luggage. The storage provider is responsible for the performance of the agreement. This means that all claims and complaints regarding your luggage storage must be directed to the Storage Provider, for example, as a result of delays, cancellations, bankruptcy, theft, and damage to the luggage, as well as compliance with national laws, public regulations (including, without limitation to the applicable tax rules and baggage storage rules) or other rules related to the Storage Services, etc.
      </p>
      <p>
        Thus, Crypto Luggage does not warrant that the Platform is or will be secure, timely, accurate, complete, uninterrupted, without errors, or free of viruses, defects, or other harmful components or other program limitations. Crypto Luggage hereby disclaims all warranties and conditions regarding the use of the Platform, including but not limited to the availability, reliability, or quality of the Platform. Crypto Luggage is not responsible for any error, fault, or mistake related to any content and information displayed within the Platform.
      </p>
      <p>
        Crypto Luggage is not and shall not be responsible for your use of any Storage Services distributed through the Platform, including the nature and quality of the service given by the Storage Provider.
      </p>
      <p>
        Crypto Luggage is not a party to any agreement entered into between Storage Service Providers and Travelers, nor is Crypto Luggage a luggage storage provider or insurer under any circumstances, and is not and shall not be responsible for any error, fault, or mistake of any and all content and information received through the Platform, including by Users, Storage Service Provider or third parties. Crypto Luggage is not acting as an agent in any capacity for any Member except as specified in the Payments Terms. Please note that Crypto Luggage does not endorse any entity, product, or service mentioned in any User Generated Content available on the Platform. Crypto Luggage makes no warranty or assurance to you with respect to the existence, scope, and compliance as required by any applicable law with respect to such Storage Services. Crypto Luggage is not responsible and has no liability for any product or service provided by any person or entity other than Crypto Luggage. Crypto Luggage bears no responsibility for any engagement with third-party service providers or any service and/or content supplied by a third party.
      </p>
      <p>
        Storage Service Providers are independent contractors and are not to be considered an employee, agent, partner, or joint venture of Crypto Luggage. Storage Service Providers shall not bind nor attempt to bind Crypto Luggage to any contract.
      </p>
      <p>
        You agree and accept that the use of the Platform and the services provided is entirely at your own risk.
      </p>

      <h3>Limitation of Liability</h3>
      <p>
        The use of Crypto Luggage’s Platform and participation in any Storage Services is solely at your own risk. To the maximum extent legally permissible, Crypto Luggage is not liable for any damages, hereunder but not limited to, direct, indirect, punitive, incidental, or consequential damages of any kind (including without limitation loss of goodwill, profits, or data) resulting from or arising out of or relating to the Platform, Crypto Luggage services, and/or your participation in any Storage Services.
      </p>

      <h3>Indemnification</h3>
      <p>
        You agree to defend, indemnify, and hold harmless Crypto Luggage from and against any and all claims, damages, obligations, losses, liabilities, costs, debts, and expenses arising from (i) your use of Crypto Luggage’s Platform; (ii) your violation of any term of these User terms and conditions; (iii) your violation of any third-party rights, including without limitation any intellectual property rights or privacy right of such third party in connection with your use of the Platform; and/or (iv) any damage of any sort, whether direct, indirect, special, or consequential, you may cause to any third party with relation to your use of the Platform.
      </p>

      <h3>Amendments to the User terms and conditions</h3>
      <p>
        Crypto Luggage reserves the right to change the User terms and conditions from time to time, so please re-visit this page frequently. In case of any material change, we will make reasonable efforts to post a clear notice on the Platform and/or will send you an email (to the extent that you provided us with such email address) regarding such change. In the event that the User terms and conditions must be amended to comply with any legal requirements, the amendments may take effect immediately or as required by the law and without any prior notice.
      </p>

      <h3>Termination</h3>
      <p>
        Crypto Luggage reserves the right to block your access to the Platform and/or temporarily or permanently limit your access to the Platform, at its sole discretion, in addition to any other remedies that may be available to Crypto Luggage under any applicable law. Such actions by Crypto Luggage may be taken, for example, if Crypto Luggage deems that you have breached the User terms and conditions in any manner. Additionally, Crypto Luggage may at any time, at its sole discretion, cease the operation of the Platform or any part thereof, temporarily or permanently, without giving any prior notice. You agree and acknowledge that Crypto Luggage does not assume any responsibility with respect to or in connection with the termination of the Platform’s operation and loss of any data. The provisions of these User terms and conditions survive the termination of these User terms and conditions.
      </p>

      <h3>Advertisements</h3>
      <p>
        Crypto Luggage may accept advertisements from third parties. Please be aware that Crypto Luggage makes no warranties or representations concerning such advertisements, whether Crypto Luggage has control over such advertisements. Any and all information contained in such advertisements belongs solely to the advertisers. Crypto Luggage will not be liable for any damage or loss incurred to you as a result of or in connection with such advertisements.
      </p>

      <h3>Law and jurisdiction</h3>
      <p>
        The User terms and conditions are governed by and shall be construed in accordance with Danish law. Any dispute arising from or related to your use of Crypto Luggage’s Platform and/or services shall be settled by the City Court of Copenhagen (in Danish: Københavns Byret) in accordance with Danish law.
      </p>
      <p>
        Should any provision of these User terms and conditions be held by a court of competent jurisdiction to be illegal, invalid, or unenforceable, such provision may be modified by such court in compliance with the law giving effect to the intent of the parties and enforced as modified. All other terms and conditions of these User terms and conditions shall remain in full force and effect and be construed in accordance with the modified provision.
      </p>
      <p>
        Crypto Luggage reserves the right to assign or transfer the rights under these User terms and conditions without restriction or notification.
      </p>

      <h3>For information, questions, or notification of errors, please contact:</h3>
      <p>
        Suppose you have any questions regarding the User terms and conditions. In that case, you are always welcome to contact customer service at info@cryptoluggagestorage.com, and we will make an effort to reply within a reasonable time.
      </p>
    </div>
  );
};

export default TermsAndConditions;