import React from "react";
import {GetPaymentStatus} from "../../../common/api/stripe";
import {useQuery} from "@tanstack/react-query";
import {useNavigate} from "react-router-dom";
import {Button, Result, Spin} from "antd";

const PaymentStatus = () => {
  const navigate = useNavigate();
  const params = new URLSearchParams(window.location.search);
  const sessionId = params.get("session_id");

  const sessionQuery = useQuery({
    queryKey: ["paymentStatus", sessionId],
    queryFn: () => GetPaymentStatus(sessionId),
    staleTime: 1000 * 60 * 15, // 15 minutes
  });

  return (
    <div>
      <h2>Payment Status</h2>
      <p>
        {sessionQuery.isLoading
          ? "Processing... Do not refresh or navigate away until the process is complete."
          : ""}
      </p>
      {(!sessionQuery.isLoading && sessionQuery.data?.data?.paymentIntent?.status === "succeeded") ?
      <Result
        status="success"
        title="Successfully Purchased Storage Booking!"
        // subTitle="Order number: 2017182818828182881 Cloud server configuration takes 1-5 minutes, please wait."
        extra={[
          <Button type="primary" className="yellow-btn" onClick={() => {
            navigate("/user");
          }} key="console">
            Dashboard
          </Button>
        ]}
      />
        :
        sessionQuery.isLoading ?
          <Spin size="large" /> :
        <Result
          status="error"
          title="Payment Failed"
          extra={[
            <Button type="primary" className="yellow-btn" onClick={() => {
              navigate("/user");
            }} key="console">
              Dashboard
            </Button>
          ]}
        />
      }
    </div>
  );
};

export default PaymentStatus;
