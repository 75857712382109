/*
 * Copyright © 2023-2024 www.4bex.com
 *
 * Licensed under the www.4bex.com License, Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.4bex.com/license
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React, { useEffect, useRef, useState } from "react";
import { Button, Layout, List, Skeleton, Spin } from "antd";
import {
  GoogleMap,
  InfoWindowF,
  Marker,
  useJsApiLoader,
} from "@react-google-maps/api";
import PlaceCard from "../../common/components/place-card/PlaceCard";
import "./Booking.css";
import { mapStyles } from "../../common/configs/mapStyles";
import MapIcon from "../../assests/images/mapicon3.png";
import { useNavigate, useSearchParams } from "react-router-dom";
import { googleMapsConfig } from "../../common/configs/googleMap";
import { BarsOutlined, GlobalOutlined } from "@ant-design/icons";
import { useInfiniteQuery } from "@tanstack/react-query";
import { getStorageNearby } from "../../common/api/storage";
import LuggagePopup from "./modal/LuggagePopup";

const { Content } = Layout;

const getTodayDate = () => {
  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, "0"); // Months are 0-based
  const day = String(today.getDate()).padStart(2, "0");

  return `${year}-${month}-${day}`; // Format: YYYY-MM-DD
};

const Booking = () => {
  const defaultCenter = { lat: 6.927079, lng: 79.861244 };
  const [mapCenter, setMapCenter] = useState(defaultCenter);
  const [selectedPlace, setSelectedPlace] = useState(null); // Determines if more data can be loaded

  const isEffectRun = useRef(false);

  const navigate = useNavigate();

  const [searchParams] = useSearchParams();

  const [bookingData, setBookingData] = useState({
    luggage: "",
    lat: 6.927079,
    lng: 79.861244,
    address: "",
    minCapacity: 1,
    date: getTodayDate(),
  });

  const [isModalVisible, setIsModalVisible] = useState(false);

  useEffect(() => {
    const searchParamsData = {
      date: searchParams.get("date") || getTodayDate(), // Default to empty string if not found
      luggage: searchParams.get("luggage") || "",
      lat: searchParams.get("lat") || 6.927079,
      lng: searchParams.get("lng") || 79.861244,
      address: searchParams.get("address") || "",
      minCapacity: searchParams.get("minCapacity") || 1,
    };
    setMapCenter({
      lat: Number(searchParams.get("lat")),
      lng: Number(searchParams.get("lng")),
    });
    setBookingData(searchParamsData);
  }, [searchParams]);

  const storageQuery = useInfiniteQuery({
    queryKey: [
      "storage",
      bookingData.lat,
      bookingData.lng,
      bookingData.minCapacity,
      bookingData.date,
    ],
    queryFn: ({ pageParam = 1 }) =>
      getStorageNearby({
        longitude: bookingData.lat,
        latitude: bookingData.lng,
        date: bookingData.date,
        minCapacity: bookingData.minCapacity,
        page: pageParam,
        limit: 10, // Set the desired number of items per page
      }),
    getNextPageParam: (lastPage) => {
      const { currentPage, totalPages } = lastPage?.data?.success?.meta || {};
      return currentPage < totalPages ? currentPage + 1 : undefined;
    },
    staleTime: 1000 * 60 * 15, // 15 minutes
    retry: 3,
  });

  const data =
    storageQuery.data?.pages.flatMap((page) => page.data.success.data) || [];

  const handleNavigate = (id) => {
    navigate(`/store/${id}`);
  };

  useEffect(() => {
    if (isEffectRun.current) return; // Skip if already run
    isEffectRun.current = true;
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
  }, []);

  const mapContainerStyle = {
    width: "100%",
    height: "100%",
  };

  const handleCardClick = (place) => {
    if (!selectedPlace || selectedPlace.id !== place.id) {
      setSelectedPlace(place);
      setMapCenter(place.location);
    } else {
      setSelectedPlace(null); // Toggle off if the same marker is clicked
    }
  };

  const { isLoaded } = useJsApiLoader({
    id: googleMapsConfig.id,
    googleMapsApiKey: googleMapsConfig.googleMapsApiKey,
    libraries: googleMapsConfig.libraries,
  });

  const [view, setView] = useState("list"); // 'list' or 'map'

  const handleToggleView = () => {
    setView((prevView) => (prevView === "list" ? "map" : "list"));
  };

  const handleSubmit = () => {
    setIsModalVisible(false);
  };

  return (
    <div className="app-container">
      {/* Google Map */}
      <Content
        className={`map-container map-view ${view === "map" ? "visible" : ""}`}
        style={{ position: "relative" }}
      >
        {/*<LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_API_KEY}>*/}
        {isLoaded && (
          <GoogleMap
            mapContainerStyle={mapContainerStyle}
            center={mapCenter}
            zoom={9}
            options={{
              styles: mapStyles, // Apply custom styles here
              disableDefaultUI: true, // Disables default UI like zoom controls, map type selector
            }}
          >
            {data?.map((place) => (
              <Marker
                key={place.id}
                position={place.location}
                icon={{
                  url: MapIcon,
                  scaledSize: new window.google.maps.Size(50, 50),
                }}
                onClick={() => setSelectedPlace(place)}
              />
            ))}

            {selectedPlace && selectedPlace.id !== null && (
              <InfoWindowF
                position={selectedPlace.location}
                onCloseClick={() => setSelectedPlace(null)}
              >
                <div>
                  <h4>{selectedPlace.title}</h4>
                  <p>
                    {selectedPlace.pricing.isHourly
                      ? `£${selectedPlace.pricing.hourlyRate}/hour`
                      : null}{" "}
                    {selectedPlace.pricing.isDaily
                      ? `£${selectedPlace.pricing.dailyRate}/day`
                      : null}
                  </p>
                  <Button
                    style={{ border: "none" }}
                    className="yellow-btn"
                    onClick={() => setIsModalVisible(true)}
                  >
                    Book Now
                  </Button>
                </div>
              </InfoWindowF>
            )}
          </GoogleMap>
        )}
        {/*</LoadScript>*/}
      </Content>

      <div
        className={`cards-container map-view ${
          view === "list" ? "visible" : ""
        }`}
      >
        <h2>Storages Near - {searchParams?.get("address")}</h2>
        <div>
          {storageQuery.isError ? (
            <div>{storageQuery.error?.response?.data?.error?.message}</div>
          ) : (
            <List
              dataSource={data}
              renderItem={(item) => (
                <PlaceCard
                  handleClick={handleCardClick}
                  place={item}
                  storeView={handleNavigate}
                  selectedDate={bookingData.date}
                  showBooking={() => setIsModalVisible(false)}
                />
              )}
              locale={{
                emptyText: storageQuery.isLoading ? (
                  <Skeleton />
                ) : (
                  "No more places"
                ),
              }}
            />
          )}
          {storageQuery.isFetchingNextPage ? (
            <Spin style={{ display: "block", margin: "16px auto" }} />
          ) : (
            storageQuery.hasNextPage && (
              <center>
                <Button
                  style={{ margin: "15px 0" }}
                  className="load-more-btn"
                  type="primary"
                  onClick={() => storageQuery.fetchNextPage()}
                >
                  Load More
                </Button>
              </center>
            )
          )}
        </div>
      </div>
      <div className="toggle-button">
        <Button
          icon={view === "list" ? <GlobalOutlined /> : <BarsOutlined />}
          className="toggle-btn"
          onClick={handleToggleView}
        >
          {view === "list" ? "Show Map" : "Show List"}
        </Button>
      </div>

      {isModalVisible && (
        <LuggagePopup
          data={selectedPlace}
          selectedDate={bookingData}
          visible={isModalVisible}
          onClose={() => setIsModalVisible(false)}
          handleSubmit={handleSubmit}
        />
      )}
    </div>
  );
};

export default Booking;
