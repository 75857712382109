/*
 * Copyright © 2023-2024 www.4bex.com
 *
 * Licensed under the www.4bex.com License, Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.4bex.com/license
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { useQuery } from "@tanstack/react-query";
import { Alert, Button, List, Rate, Skeleton, Tag, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { getStorageByUserId } from "../../../common/api/storage";
import { formatAddress, formatHours, sortWeekDays } from "../../../common/util";
import EditOpenHours from "./EditOpenHours";
import EditStoreContact from "./EditStoreContact";

const { Title, Text, Paragraph } = Typography;

const Storage = () => {
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openingHours, setOpeningHours] = useState(null);

  const storageQuery = useQuery({
    queryKey: ["storage"],
    queryFn: getStorageByUserId,
    staleTime: 1000 * 60 * 15, // 15 minutes
  });

  const data = storageQuery.data?.data?.success.data[0];

  useEffect(() => {
    if (data?.opening_hours) {
      setOpeningHours(data?.opening_hours);
    }
  }, [data?.opening_hours]);

  return (
    <div>
      <div className="booking-container">
        {storageQuery.isLoading ? (
          <Skeleton />
        ) : data ? (
          <div
            style={{
              maxWidth: "100vw",
              margin: "0 auto",
              padding: "5px",
              borderRadius: "12px",
            }}
          >
            <div className="title-rating">
              {/* Header */}
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div>
                  <Title level={3}>{data?.title}</Title>
                </div>
              </div>

              {/* Ratings */}
            </div>
            <div style={{ margin: "20px 0", paddingTop: "20px" }}>
              <Rate
                disabled
                defaultValue={data?.average_rating}
                style={{ color: "#faad14" }}
              />
              <Text style={{ marginLeft: "8px" }}>
                ({data?.average_rating} ratings)
              </Text>
              <Paragraph>
                <Text strong>{data?.review_count} Travelers</Text> gave this
                place 5 out of {data?.average_rating} stars!{" "}
              </Paragraph>
            </div>
            <div style={{ padding: "8px 0" }}>
              <Text strong>Store description : {data?.description}</Text>
            </div>
            <div style={{ padding: "8px 0" }}>
              <Text strong>
                Store Address : {data?.address && formatAddress(data?.address)}
              </Text>
            </div>
            <div style={{ padding: "8px 0" }}>
              <Text strong>
                Business Registration No. : {data?.registration_number}
              </Text>
            </div>
            <div style={{ padding: "8px 0" }}>
              <Text strong>
                Store Status :{" "}
                {data?.status === "approved" ? (
                  <Tag color="green">
                    {data?.status.toString().toUpperCase()}
                  </Tag>
                ) : (
                  <Tag color="red">{data?.status.toString().toUpperCase()}</Tag>
                )}
              </Text>
            </div>

            <div style={{ margin: "20px 0" }}>
              {openingHours && (
                <>
                  <hr />
                  <Title level={4}>Open Hours</Title>
                  <List
                    grid={{
                      gutter: 16,
                      xs: 1,
                      sm: 2,
                      md: 3,
                      lg: 3,
                      xl: 4,
                      xxl: 4,
                    }}
                    dataSource={Object.entries(sortWeekDays(openingHours))} // Convert storeHours object to an array
                    renderItem={([day, { open, hours }]) => (
                      <List.Item>
                        <Text style={{ marginRight: "10px" }} strong>
                          {day}{" "}
                        </Text>{" "}
                        {open ? (
                          <>
                            <Tag color="green">OPEN</Tag>
                            <pre
                              style={{
                                margin: "5px 0",
                                fontSize: "medium",
                                fontFamily: "Roboto Mono, monospace",
                              }}
                            >
                              {formatHours(hours)}
                            </pre>
                          </>
                        ) : (
                          <Tag color="red">CLOSED</Tag>
                        )}
                      </List.Item>
                    )}
                  />

                  <div>
                    <Button onClick={() => setOpenEditModal(true)}>Edit</Button>
                  </div>
                  <hr />
                </>
              )}
            </div>
            {data?.opening_hours && (
              <EditOpenHours
                setEditModal={setOpenEditModal}
                setOpeningHours={setOpeningHours}
                openHours={data.opening_hours}
                storageId={data.id}
                isEditOpenHours={openEditModal}
              />
            )}

            {data && (
              <>
                <Title level={4}>Store Contact</Title>
                <EditStoreContact
                  contactEmail={data.contact_email}
                  contactName={data.contact_name}
                  contactTel={data.contact_mobile}
                  contactWebsite={data.contact_website}
                  capacity={data.capacity}
                  storageId={data.id}
                />
              </>
            )}
          </div>
        ) : (
          <Alert
            message="No storage found"
            type="warning"
            style={{ textAlign: "center" }}
          />
        )}
      </div>
    </div>
  );
};

export default Storage;
